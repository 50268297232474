import React, { useEffect, useState, useMemo } from 'react';
import Spinner from '../../../../components/Spinner';
import ManagePayment, { ManagePaymentProps } from './ManagePayment';
import { usePaymentContext } from '../PaymentContext';
import { useGetCombinedPaymentMethods, useUpdatePayment, useDeletePayment } from '@lib-services/cps/queries';
import {
  CardMethodDetails,
  CombinedPaymentMethods,
  PaymentMethodDetails,
  PaymentOptions,
  PaymentOptions_,
} from '@lib-payment/Types';
import { useToggle } from '@lib-hooks';
import PaymentButton from '../PaymentButton';
import { SubTitle } from '@styled-components/globalStyles';
import DeletePaymentModal from './components/DeletePaymentModal';
import NoPaymentsMethods from './NoPaymentsMethods';

const getDefaultPayment = (paymentMethods: CombinedPaymentMethods) => {
  return paymentMethods?.find(({ defaultPaymentMethod }) => defaultPaymentMethod);
};

const getDefaultPaymentId = (payment?: CombinedPaymentMethods) => {
  return getDefaultPayment(payment)?.paymentMethodId ?? '';
};

export const getTruncatedEmail = (email: string) => {
  const [name, address] = email.split('@');
  return `${name.slice(0, 3)}...${name.slice(-1)}@${address}`;
};

export const ManagePaymentContainer: React.FC<ManagePaymentProps> = ({ managePage, children }) => {
  const { submitCallback, subscriptionProps, content } = usePaymentContext();
  const [paymentToEdit, setPaymentToEdit] = useState<CardMethodDetails>();
  const [createNewPayment, setCreateNewPayment] = useToggle();
  const [defaultPaymentId, setDefaultPaymentId] = useState<string>('');
  const [cardToDelete, setCardToDelete] = useState<PaymentMethodDetails>();

  const {
    isLoading: paymentMethodsLoading,
    data: paymentMethods,
    isSuccess,
  } = useGetCombinedPaymentMethods(subscriptionProps);

  useEffect(() => {
    if (!!managePage && isSuccess && paymentMethods.length === 0) {
      setCreateNewPayment(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    // set initial default payment
    if (isSuccess) {
      const defaultPayment = getDefaultPaymentId(paymentMethods);
      setDefaultPaymentId(defaultPayment);

      if (!defaultPayment && !createNewPayment) {
        setCreateNewPayment(true);
      }

      // we need to update details of payment we edit
      if (paymentToEdit) {
        setPaymentToEdit(
          (payment) =>
            paymentMethods.find(
              (paymentMethod) => paymentMethod.paymentMethodId === payment.paymentMethodId,
            ) as CardMethodDetails,
        );
      }
    }
  }, [isSuccess, paymentMethods]);

  const { mutateAsync: deletePayment, isLoading: isPaymentDeleting } = useDeletePayment(subscriptionProps);

  const { mutateAsync, isLoading } = useUpdatePayment(subscriptionProps);

  const cancelEditing = () => {
    setPaymentToEdit(undefined);
    setCreateNewPayment(false);
  };

  const handleSetDefault = async (paymentMethodId: string) => {
    if (!managePage) {
      await mutateAsync({
        paymentMethodId,
        defaultPaymentMethod: true,
      });
    }

    setDefaultPaymentId(paymentMethodId);
  };

  const handleCreateNewPayment = (state: boolean) => {
    setPaymentToEdit(undefined);
    setCreateNewPayment(state);
  };

  const handleEditPayment = (details: CardMethodDetails) => {
    setCreateNewPayment(false);
    setPaymentToEdit(details);
  };

  const handleContinue = () => {
    submitCallback({
      paymentMethodId: defaultPaymentId,
      paymentOption:
        paymentMethods.find((paymentMethod) => paymentMethod.paymentMethodId === defaultPaymentId)?.cardType ||
        PaymentOptions.CREDIT_CARD,
    });
  };

  const removePayment = async (paymentMethodToDelete: PaymentMethodDetails) => {
    await deletePayment(paymentMethodToDelete?.paymentMethodId);
    cancelEditing();
    setCardToDelete(null);
  };

  const deleteLabel = useMemo(() => {
    if (!cardToDelete) {
      return '';
    }

    let deleteMessage = content.deletePaymentMethodConfirmation?.trim();
    if (cardToDelete?.cardType === PaymentOptions.PAYPAL) {
      return `${deleteMessage} ${getTruncatedEmail(cardToDelete.email)}`;
    }

    return `${deleteMessage} ****${cardToDelete.cardNumber.slice(-4)}`;
  }, [cardToDelete, content.deletePaymentMethodConfirmation]);

  const subTitle = useMemo(() => {
    const { isSinglePaymentMethod } = subscriptionProps;
    const paymentMethodsCount = paymentMethods.length;

    if (paymentMethodsCount === 0) {
      return content?.titles?.addPaymentMessage;
    }

    if (isSinglePaymentMethod) {
      return content?.titles?.replaceOrEditPaymentMessage;
    }

    if (paymentMethodsCount > 1) {
      return content?.titles?.selectDefaultMessage;
    }

    return content?.titles?.addOrEditPaymentMessage;
  }, [subscriptionProps.isSinglePaymentMethod, paymentMethods.length]);

  const hasNoPaymentMethods = paymentMethods.length === 0;
  const isFirstPaymentMethod = managePage && hasNoPaymentMethods;

  // We need to default label to `add payment` in case if some OEM doesn't need this feature to show different labels based on amount of cards
  const replacePaymentLabel = content.replacePaymentMethod ?? content.addPaymentMethod;
  const addPaymentLabel =
    !hasNoPaymentMethods && subscriptionProps.isSinglePaymentMethod
      ? // Show replace only in case if there some cards on file and we should show only one card on UI
        // as it will replace existing card after user submit payment form
        replacePaymentLabel
      : // Show add, if OEM supports multiple payment methods
        // or if this is first card to add
        content.addPaymentMethod;

  // We override content with correct label
  content.addPaymentMethod = addPaymentLabel;

  if (!managePage && hasNoPaymentMethods) {
    return <NoPaymentsMethods />;
  }

  return (
    <>
      {(paymentMethodsLoading || isLoading || isPaymentDeleting) && <Spinner />}
      <DeletePaymentModal
        isShown={!!cardToDelete}
        onYes={() => removePayment(cardToDelete)}
        onNo={() => setCardToDelete(null)}
        onCancel={() => setCardToDelete(null)}
      >
        {deleteLabel}
      </DeletePaymentModal>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <ManagePayment
        setDefault={handleSetDefault}
        paymentMethods={paymentMethods}
        content={content}
        defaultPaymentId={defaultPaymentId}
        paymentToEdit={paymentToEdit}
        setPaymentToEdit={handleEditPayment}
        createNewPayment={createNewPayment}
        setCreateNewPayment={handleCreateNewPayment}
        cancelEditing={cancelEditing}
        removePayment={setCardToDelete}
        children={children}
        isFirstPaymentMethod={isFirstPaymentMethod}
      />
      {managePage && !paymentToEdit && !createNewPayment && (
        <PaymentButton data-testid="submit-payment" onContinue={handleContinue} />
      )}
    </>
  );
};
