import { styled } from '@mui/material';
import Button from '../../../components/Button';
import Grid from '@mui/material/Grid';
import { breakpoint, Container, Content } from '../../../styled-components/globalStyles';

export const PackagesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
`;

export const TrialPackagesContainer = styled(Container)`
  margin-top: 2.5rem;
`;

export const ContentSection = styled(Content)`
  margin: 1rem 0;
`;

export const TrialOption = styled(Container)`
  margin: 0.7rem 0;
`;

export const TrialOptionContent = styled(Content)`
  margin: 0rem 1.5rem 0.2rem 1.7rem;
`;

export const GridContainer = styled(Grid)`
  font-size: 0.9375rem;
  margin-left: 1.7rem;
`;

export const GridItemHeader = styled(Grid)`
  font-weight: 600;
`;

export const GridItem = styled(Grid)`
  padding: 0.2rem 0rem;
`;

export const DiscountSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${(props) => props.theme.palette.primary.main};

  @media only screen and ${breakpoint.device.md} {
    flex-direction: row;
  }
`;

export const DiscountHeader = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.4rem;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.background.default};
  width: 100%;
  text-align: center;
  padding: 1.5rem;
  box-sizing: border-box;
  font-size: 2.3125rem;
  font-weight: 700;

  @media only screen and ${breakpoint.device.md} {
    align-self: stretch;
  }
`;

export const DiscountInfo = styled('div')`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 0.9375rem;
  padding: 1.5rem;
  width: 100%;

  @media only screen and ${breakpoint.device.md} {
    align-items: flex-start;
    justify-content: center;
  }
`;

export const RegularPriceLabel = styled('div')`
  font-size: 1.9375rem;
  font-weight: 700;
  text-align: center;
`;

export const CurrentPriceLabel = styled('div')`
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
`;

export const DiscountExpirySection = styled(DiscountSection)`
  border: 1px solid darkgray;
  display: flex;
  flex-direction: column;
`;

export const DiscountExpiryHeader = styled(DiscountHeader)`
  background: #fff;
  color: #000;
`;

export const DiscountContent = styled(Content)`
  padding: 2rem;
  padding-top: 0;
  text-align: center;
`;

export const PromoCodeSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and ${breakpoint.device.md} {
    align-items: flex-start;
  }
`;

export const PromoCodeLabel = styled('div')`
  font-size: 1rem;
  font-weight: bold;
`;

export const PromoCodeRow = styled('div')`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 0 1rem 0;
`;

export const IconSection = styled('span')`
  display: flex;
  justify-content: center;
`;

export const PromoCodeValue = styled('div')`
  font-size: 1.125rem;
  font-weight: 400;
`;

export const ToggleButton = styled(Button)`
  font-size: 0.9375rem;
  font-weight: 700;
  height: 50px;
  margin: 0.75rem 0 0 0;
  max-width: 227px;
  text-transform: none;
  width: 90%;
`;

export const DiscountHeaderPackage = styled('div')`
  font-size: 1.75rem;
`;
