import React, { useState } from 'react';
import { PaymentContextProvider, SubscriptionProps, Button } from '@app/components-lib';
import Markdown from '@components/Markdown';
import { useGenerateSubscriptionContent, useGenerateSubscriptionProps } from '@components/SubscriptionWrapper/utils';
import NavigationProp from '@customTypes/NavigationProp';
import { Box, Stack, Typography } from '@mui/material';
import { useCancelLabels } from '../CancelLabelsContext';
import PreviewOrderList from '../PreviewOrderList';
import ScreenStepHeader from '../StepScreenHeader';
import Checkbox from '@lib-components/Checkbox';
import { useIsMutating } from '@tanstack/react-query';

type Props = { onContinue: () => void } & NavigationProp;
const PaymentMethods = ({ Navigation, onContinue }: Props) => {
  const [agree, setAgree] = useState(false);

  const {
    assetsRaw,
    subHeaders,
    subscriptionLabels,
    paymentHeader,
    paymentSubHeader,
    reviewOrderTerms,
    orderSummary,
    saveAndContinue,
  } = useCancelLabels();
  const content = useGenerateSubscriptionContent({
    labels: [subscriptionLabels],
    assets: assetsRaw,
  });

  const paymentProps: SubscriptionProps = useGenerateSubscriptionProps();
  const webContent = {
    ...content,
    commonWebContent: {
      ...content.commonWebContent,
      continueButtonLabel: content.commonWebContent.saveLabel,
      saveAndContinueButtonLabel: content.commonWebContent.saveLabel,
    },
    titles: subHeaders,
  };

  const previewOrderIsLoading = useIsMutating({ mutationKey: ['previewOrder'] });

  return (
    <Stack spacing={2}>
      <ScreenStepHeader>{paymentHeader}</ScreenStepHeader>

      <h3>{orderSummary}</h3>
      <PreviewOrderList />

      <Typography variant="body1">{paymentSubHeader}</Typography>

      <PaymentContextProvider content={webContent} subscriptionProps={paymentProps}></PaymentContextProvider>

      <Stack direction={'row'} alignItems={'flex-start'} mb={3}>
        <Checkbox
          sx={{
            svg: {
              width: '1.5rem',
              height: '1.5rem',
              borderRadius: 'unset',
              border: '1px solid',
              borderColor: (theme) => theme.palette.grey.A700,
            },
          }}
          checked={agree}
          onChange={(e) => setAgree(e.target.checked)}
        />
        <Markdown>{reviewOrderTerms}</Markdown>
      </Stack>

      <Box>
        <Button
          sx={(theme) => ({
            width: '100%',
            mb: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
              mr: theme.spacing(1),
              mb: 0,
              width: 'auto',
            },
          })}
          disabled={!agree || !!previewOrderIsLoading}
          onClick={onContinue}
        >
          {saveAndContinue}
        </Button>
        {Navigation && <Navigation />}
      </Box>
    </Stack>
  );
};

export default PaymentMethods;
