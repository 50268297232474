import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@components-lib';
import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { generateLabelsObjects } from '@utils/labels';
import { PackageFlag } from '@customTypes/package-flags';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';

import styles from './SubscriptionManagementButtons.module.css';
import { SUBSCRIPTION_PAGE_URL } from '@components/App/UrlParser';
import usePreferencesSelector from '@redux/selectors/preferences';
import useSubscribedPackages from '@api/queries/useSubscribedPackages';

type Props = {
  labels: {
    componentType: string;
    name: string;
    content: Array<{
      componentType: string;
      name: string;
      labelKey: string;
      labelValue: string;
      analyticsEventName?: string;
    }>;
  };
};

export const SubscriptionManagementButtons = ({ labels }: Props): JSX.Element => {
  const history = useHistory();
  const { activeButtonText, inactiveButtonText, cancelButtonText } = generateLabelsObjects(labels?.content);
  const preferences = usePreferencesSelector();

  const { data: selectedVehiclePackages } = useSubscribedPackages();

  const isSubscribed = useMemo(() => {
    const packages = selectedVehiclePackages?.packages;

    if (!packages?.length) {
      return false;
    }

    let allPackagesCancelled = true;
    let isDefaultsOnly = true;
    let containsActivePackage = false;

    packages.forEach(({ cancelReceiveDate, packageType, active }) => {
      if (!cancelReceiveDate) {
        allPackagesCancelled = false;

        // monthly cancelled packages are still marked as active packages
        if (active) {
          containsActivePackage = true;
        }
      }

      if (packageType !== PackageType.Default) {
        isDefaultsOnly = false;
      }
    });

    if (allPackagesCancelled) {
      return false;
    }

    if (isDefaultsOnly) {
      const flagDetailsOfDefaultPackage = preferences.packageFlags?.find(
        ({ componentType, name, isActive, priority, ...flag }: PackageFlag) =>
          Object.keys(flag).length === 1 && flag?.packageType === PackageType.Default,
      );

      return !flagDetailsOfDefaultPackage || flagDetailsOfDefaultPackage.isActive;
    }

    return containsActivePackage;
  }, [selectedVehiclePackages]);

  const navigate = useCallback(
    (route: string) => () => {
      history.push(route);
    },
    [history],
  );

  const mainButton = isSubscribed ? activeButtonText : inactiveButtonText;

  return (
    <div className={styles['container']}>
      <Button
        variant="outlined"
        onClick={navigate(SUBSCRIPTION_PAGE_URL)}
        data-analyticseventname={formatAnalyticsEventName(mainButton?.analyticsEventName)}
      >
        {mainButton?.primary}
      </Button>
      {isSubscribed && (
        <Button
          variant="text"
          onClick={navigate('/cancel')}
          data-analyticseventname={formatAnalyticsEventName(cancelButtonText?.analyticsEventName)}
        >
          {cancelButtonText?.primary}
        </Button>
      )}
    </div>
  );
};
