import React, { forwardRef } from 'react';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import useSubscribedPackages from '@api/queries/useSubscribedPackages';
import { useCancelLabels } from '../../CancelLabelsContext';
import Checkbox from '@lib-components/Checkbox';
import Loader from '@components/Loader';
import CustomerPackage from './CustomerPackage';

import styles from '../../Cancellation.module.css';
import { useCancelMachineContext } from '@components/Cancellation/CancelSubscriptionStateMachineWrapper';
import NavigationProp from '@customTypes/NavigationProp';
import { Stack } from '@mui/material';

export type SelectPackagesToCancelProps = {
  onSelect: (pkg: SubscribedPackage) => void;
} & NavigationProp;

const SelectPackagesToCancel = forwardRef(({ onSelect, Navigation }: SelectPackagesToCancelProps, _ref) => {
  const { isLoading } = useSubscribedPackages();

  const { subscribedPackages } = useCancelMachineContext();

  const { selectSubscription } = useCancelLabels();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Stack component="div" ref={_ref as React.RefObject<HTMLDivElement>}>
      <h3 className={styles['cancel-layout-header']}>{selectSubscription}</h3>
      <ul className={styles['customer-packages-list']}>
        {subscribedPackages.map((subscribedPackage) => {
          return (
            <li key={subscribedPackage.subscriptionPackageId}>
              <CustomerPackage
                customerPackage={subscribedPackage}
                checkbox={
                  <Checkbox
                    className={styles['select-to-cancel-checkbox']}
                    onChange={() => onSelect(subscribedPackage)}
                    checked={subscribedPackage.isSelected || subscribedPackage.isAutoSelected}
                    disabled={subscribedPackage.isAutoSelected}
                    placeholder={subscribedPackage.packageId}
                  />
                }
              />
            </li>
          );
        })}
      </ul>
      {Navigation && <Navigation />}
    </Stack>
  );
});

export default SelectPackagesToCancel;
