import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Content, HSpace } from '../../../../styled-components/globalStyles';
import { CardDetail, CardType } from '../styles';
import { useForm } from 'react-hook-form';
import { Input } from '../../../../components/Input';
import { CardMethodDetails } from '@lib-payment/Types';
import { getPostalCodeRegex } from '@manageSubscription/utils';
import { buildPaymentTransactionInfo } from '../../../builders';
import Image from '../../../../components/Image';
import { useUpdatePayment } from './useUpdatePayment';
import PaymentButton from '../PaymentButton';
import { usePaymentContext } from '../PaymentContext';
import { get } from 'lodash';
import { Path } from '../../../../typesUtils';
import Link from '../../../../components/Link';
import SectionHeader from '../ManagePayment/components/SectionHeader';
import Spinner from '../../../../components/Spinner';
import { Stack } from '@lib-components/Stack';
import { EventDataBuilder, EventType, sendAnalyticsEvent } from '@lib-components/Analytics';

interface UpdatePaymentProps {
  paymentInfo: CardMethodDetails;
  cancelEdit?: () => void;
  onDelete?: (arg: CardMethodDetails) => void;
}

type PaymentInfo = CardMethodDetails & { expiration: string };

type UpdatePaymentInputProps = {
  field: Path<PaymentInfo>;
  defaultValue?: string | number;
};

const UpdatePayment = ({ paymentInfo, cancelEdit, onDelete }: UpdatePaymentProps) => {
  const { setUpdatedPayment, source, transactionId, clientIP, isLoading } = useUpdatePayment({
    onDone: () => {
      sendAnalyticsEvent(new EventDataBuilder(EventType.FormSucceededEvent).withArgs({ name: 'update payment' }));
      cancelEdit?.();
    },
    onError: (e) => {
      sendAnalyticsEvent(
        new EventDataBuilder(EventType.FormFailedEvent).withArgs({
          name: 'update payment',
          error: (e as Error).message,
        }),
      );
    },
  });
  const {
    content: {
      requiredFieldErrorMsg,
      invalidInputFieldErrorMsg,
      commonWebContent: { deleteLabel },
      creditCardInfo,
      cardNumberFieldLabel,
      expirationFieldLabel,
      cardholderNamedFieldLabel,
      addressLine1FieldLabel,
      addressLine2FieldLabel,
      zipCodeFieldLabel,
      assets,
    },
    subscriptionProps,
  } = usePaymentContext();

  const {
    userDetails: { billingAddress },
  } = subscriptionProps;

  const { paymentMethodId, defaultPaymentMethod, cardType, cardNumber, expirationMonth, expirationYear } = paymentInfo;

  const paymentSchema = yup.object().shape({
    cardHolderInfo: yup.object().shape({
      cardHolderName: yup
        .string()
        .required()
        .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi)
        .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms),
      addressLine1: yup
        .string()
        .required()
        .matches(/^([A-Za-z\u0020\u0026\u0027\u002D\d\s]*)$/gi),
      addressLine2: yup.string().matches(/^([A-Za-z\u0020\u0026\u0027\u002D\d\s]*)$/gi),
      zipCode: yup.string().required().matches(getPostalCodeRegex(billingAddress.country)),
    }),
    expiration: yup
      .string()
      .required()
      .matches(/^(0[1-9]|1[0-2])\/\d{4}$/),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<PaymentInfo>({
    mode: 'onChange',
    resolver: yupResolver(paymentSchema),
    defaultValues: paymentInfo,
  });

  useEffect(() => {
    // This updated our form with new data
    // when we choose to edit new card
    reset(paymentInfo);
  }, [paymentInfo]);

  const onSubmit = handleSubmit(async (paymentInfo: PaymentInfo) => {
    const { expiration } = paymentInfo;
    const [expirationMonth, expirationYear] = expiration.split('/');

    setUpdatedPayment({
      ...paymentInfo,
      paymentMethodId,
      defaultPaymentMethod,
      expirationMonth,
      expirationYear,
      transactionInfo: buildPaymentTransactionInfo(subscriptionProps, transactionId, clientIP, source),
    });
  });

  const cardExpiration = () => {
    return expirationMonth && expirationYear ? `${`0${expirationMonth}`.slice(-2)}/${expirationYear}` : '';
  };

  const getErrorMessage = (id: string) => {
    const error = get(errors, id);
    if (!error?.message) {
      return null;
    }
    return error?.type === 'required' ? requiredFieldErrorMsg : invalidInputFieldErrorMsg;
  };

  const fieldLabels: Partial<Record<string, string>> = {
    cardNumberFieldLabel,
    expirationFieldLabel,
    cardHolderNameFieldLabel: cardholderNamedFieldLabel,
    addressLine1FieldLabel,
    addressLine2FieldLabel,
    zipCodeFieldLabel,
  };

  const UpdatePaymentInput = ({ field, defaultValue }: UpdatePaymentInputProps) => {
    const [fieldPath, fieldName] = field.split('.');

    return (
      <Input
        id={field}
        label={fieldLabels[`${fieldName || fieldPath}FieldLabel`]}
        defaultValue={defaultValue ?? (get(paymentInfo, field) as string | number)}
        register={register(field)}
        errorMsg={getErrorMessage(field)}
      />
    );
  };

  return (
    <div>
      {isLoading && <Spinner />}
      <SectionHeader>{creditCardInfo}</SectionHeader>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline">
        <CardDetail>
          <Content>{cardNumberFieldLabel}</Content>
          <CardType>
            <Image assets={assets} name={cardType} />
            <HSpace />
            <Content>{cardNumber}</Content>
          </CardType>
        </CardDetail>
        {!defaultPaymentMethod && !!onDelete && (
          <Link role="button" component="button" onClick={() => onDelete(paymentInfo)} color="primary">
            {deleteLabel}
          </Link>
        )}
      </Stack>
      <form onSubmit={onSubmit}>
        <UpdatePaymentInput field="expiration" defaultValue={cardExpiration()} />
        <UpdatePaymentInput field="cardHolderInfo.cardHolderName" />
        <UpdatePaymentInput field="cardHolderInfo.addressLine1" />
        <UpdatePaymentInput field="cardHolderInfo.addressLine2" />
        <UpdatePaymentInput field="cardHolderInfo.zipCode" />

        <PaymentButton type="submit" data-testid="update-payment" onCancel={cancelEdit} />
      </form>
    </div>
  );
};

export default UpdatePayment;
