import React, { ElementType } from 'react';
import { Route } from 'react-router-dom';
import AnalyticsTrackView from '@components/Analytics';
import './App.css';

export interface AppRouteProps {
  Component: ElementType;
  componentProps?: Record<string, unknown>;
  path: string | Array<string>;
}

export const AppRoute = ({ Component, componentProps = {}, path }: AppRouteProps) => {
  const { analyticsEventName = '' } = componentProps;
  return (
    <Route
      exact
      path={path}
      render={(routerProps) => (
        <AnalyticsTrackView analyticsEventName={analyticsEventName}>
          <div className="PageContainer">
            <Component {...routerProps} {...componentProps} />
          </div>
        </AnalyticsTrackView>
      )}
    />
  );
};
