import React from 'react';
import { useCancelLabels } from '../CancelLabelsContext';
import Markdown from '@app/components-lib/components/Markdown';
import { Stack } from '@mui/material';

const LastMessage = () => {
  const { lastMessage } = useCancelLabels();

  return (
    <Stack>
      <Markdown>{lastMessage}</Markdown>
    </Stack>
  );
};

export default LastMessage;
