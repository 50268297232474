import { OrderRequest, SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createOrder, previewOrder } from '@cv/portal-cps-lib';
import { useConfig } from '@components/ConfigProvider';
import { useSelector } from 'react-redux';
import useAccount from './useAccount';
import { getCookie } from '@utils/cookies';
import { TokenTypes } from '@api/services/StoreService';
import useVehicle from './useVehicle';
import { CONFIG_KEYS, generateTransactionId } from '@app/components-lib';
import { buildOrderTransactionInfo } from '@app/components-lib/manageSubscription/builders';
import { getClientIp } from '@app/components-lib/services/commonService';
import { CancelReason, CancellationPolicy } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { EligiblePackageInfo } from '@manageSubscription/Types';
import { formatToCpsTZ, isAnnualTerm } from '@manageSubscription/utils/subscriptionUtils';
import { isTrialPackage, isDefaultPackage, isPackageFutureDated, isAddOnPackage } from '@manageSubscription/utils';
import useServices from './useServices';
import attachCapableServices from '@api/requests/subscription/attachCapableServices';

const buildPackages = (
  packagesToRemove: SubscribedPackage[],
  packagesToAdd: EligiblePackageInfo[],
  cancelReason?: string,
) => {
  if (!cancelReason) {
    cancelReason = CancelReason.Cancelled_cancel;
  }
  const _packagesToRemove = packagesToRemove.map((pkg) => {
    const isCancelEffectiveToday =
      isTrialPackage(pkg.variant) ||
      isAnnualTerm(pkg) ||
      isPackageFutureDated(pkg) ||
      isDefaultPackage(pkg) ||
      isAddOnPackage(pkg.packageType);

    return {
      cancelReason,
      subscriptionPackageId: pkg.subscriptionPackageId,
      cancellationPolicy: isCancelEffectiveToday ? CancellationPolicy.Effective_date : CancellationPolicy.End_of_term,
      cancelEffectiveDate: isCancelEffectiveToday ? formatToCpsTZ() : '',
    };
  });

  const _packagesToAdd = packagesToAdd.map((pkg, index) => {
    return {
      autoRenew: pkg.autoRenew,
      packageVariantId: pkg.variant.id,
      sequence: index + 1,
      discountIds: pkg.variant.discounts.map((discount) => discount.id),
    };
  });

  return [_packagesToRemove, _packagesToAdd];
};

export type OrderParams = {
  packagesToRemove: SubscribedPackage[];
  packagesToAdd: EligiblePackageInfo[];
  reason: string;
};
const useOrders = () => {
  const queryClient = useQueryClient();
  const config = useConfig();
  const locale = useSelector(({ settingsReducer }) => settingsReducer?.locale);
  const { data: account } = useAccount();
  const { data: vehicle } = useVehicle();
  const environmentEnum = config.getEnvironmentEnum();
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const {
    data: { capableServices = [] },
  } = useVehicle();

  const tranSource = config.getOemValue(CONFIG_KEYS.TRANS_SOURCE);

  const { mutateAsync: createOrderMutation } = useMutation({
    mutationKey: ['createOrder'],
    mutationFn: async ({ packagesToRemove = [], packagesToAdd = [], reason }: OrderParams) => {
      const clientIP = await getClientIp();
      const transactionId = generateTransactionId();
      const transactionInfo = buildOrderTransactionInfo(tranSource, transactionId, clientIP);

      const [_packagesToRemove, _packagesToAdd] = buildPackages(packagesToRemove, packagesToAdd, reason);

      const request: OrderRequest = {
        environment: environmentEnum,
        headers: {
          Authorization: accessToken,
          'CV-Correlation-Id': '',
          'Accept-Language': locale,
          'CV-Tenant-Id': account?.tenantId,
        },
        data: {
          packagesToRemove: _packagesToRemove,
          packagesToAdd: _packagesToAdd,
          userId: account?._id,
          vin: vehicle?.vin,
          transactionInfo,
        },
      };

      const response = await createOrder(request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['subscribedPackages'] });
      queryClient.invalidateQueries({ queryKey: ['eligiblePackages'] });
    },
  });

  const { mutateAsync: previewOrderMutation } = useMutation({
    mutationKey: ['previewOrder'],
    mutationFn: async ({ packagesToRemove = [], packagesToAdd = [], reason }: OrderParams) => {
      const clientIP = await getClientIp();
      const transactionId = generateTransactionId();
      const transactionInfo = buildOrderTransactionInfo(tranSource, transactionId, clientIP);

      const [_packagesToRemove, _packagesToAdd] = buildPackages(packagesToRemove, packagesToAdd, reason);

      const request: OrderRequest = {
        environment: environmentEnum,
        headers: {
          Authorization: accessToken,
          'CV-Correlation-Id': '',
          'Accept-Language': locale,
          'CV-Tenant-Id': account?.tenantId,
        },
        data: {
          packagesToRemove: _packagesToRemove,
          packagesToAdd: _packagesToAdd,
          userId: account?._id,
          vin: vehicle?.vin,
          transactionInfo,
        },
      };
      const response = await previewOrder(request);
      try {
        const subscribedPackagesWithServices = attachCapableServices({
          packages: response.data.subscribedPackages,
          capableServices,
        });
        response.data.subscribedPackages = subscribedPackagesWithServices;
      } catch (e) {
        console.warn('Error attaching capable services to subscribed packages');
      }

      return response.data;
    },
  });

  return {
    createOrder: createOrderMutation,
    previewOrder: previewOrderMutation,
  };
};

export default useOrders;
