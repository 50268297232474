import { Button } from '@app/components-lib';
import { useConfig } from '@components/ConfigProvider';
import { EligiblePackageInfo } from '@customTypes/PackageInfo';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { Box, Slide, SlideProps } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useCancelLabels } from './CancelLabelsContext';
import { CancelFlow, EVENTS } from './CancelSubscriptionStateMachine';
import { useCancelMachine, useCancelMachineContext } from './CancelSubscriptionStateMachineWrapper';
import styles from './Cancellation.module.css';
import AllBenefits from './steps/AllBenefits/AllBenefits';
import CancelPackages from './steps/CancelPackages';
import NextBestOffer from './steps/NextBestOffer';
import PreviewOrder from './steps/PreviewOrder';
import Promotions from './steps/Promotions';
import SelectPackagesToCancel from './steps/SelectPackagesToCancel/SelectPackagesToCancel';
import LastMessage from './steps/LastMessage';
import Error from './steps/Error';
import Payment from './steps/PaymentsMethods';

const Cancellation = () => {
  const cancelMachine = useCancelMachine();
  const { step, selectedPackages } = useCancelMachineContext();
  const { continueButton, keepButton } = useCancelLabels();
  const config = useConfig();

  const handleSelectPackage = useMemo(
    () => (selectedPackage: SubscribedPackage) => {
      cancelMachine.send(EVENTS.HANDLE_SELECT_PACKAGE, { selectedPackage });
    },
    [cancelMachine],
  );

  const handleSelectPromotion = useCallback(
    (selectedPromotion: EligiblePackageInfo) => {
      cancelMachine.send(EVENTS.HANDLE_SELECT_PROMOTION, { selectedPromotion });
    },
    [cancelMachine],
  );

  const handleSelectNextBestOffer = useCallback(
    (eligiblePackage: EligiblePackageInfo) => {
      cancelMachine.send(EVENTS.SELECT_SUBSCRIBER_DISCOUNT_ELIGIBLE_PACKAGE, { eligiblePackage });
    },
    [cancelMachine],
  );

  const onContinue = useCallback(() => {
    cancelMachine.send({ type: EVENTS.NEXT_STEP });
  }, [cancelMachine]);

  const handleSetCancelReason = useCallback(
    (cancelReason) => {
      cancelMachine.send(EVENTS.SET_CANCEL_REASON, { cancelReason });
    },
    [cancelMachine],
  );

  const oemName = config.getOemName().toLowerCase();

  const ProfileButton = () => (
    <Button
      sx={(theme) => ({
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 'auto',
        },
      })}
      variant="outlined"
      component={Link}
      to="/profile"
    >
      {keepButton}
    </Button>
  );

  const CancellationNavigation = () => (
    <Box>
      <Button
        sx={(theme) => ({
          width: '100%',
          mb: theme.spacing(1),
          [theme.breakpoints.up('sm')]: {
            mr: theme.spacing(1),
            mb: 0,
            width: 'auto',
          },
        })}
        disabled={!selectedPackages.length}
        onClick={onContinue}
      >
        {continueButton}
      </Button>
      <ProfileButton />
    </Box>
  );

  const slideProps: Partial<SlideProps> = {
    timeout: { enter: 250, exit: 0 },
    mountOnEnter: true,
    unmountOnExit: true,
    direction: 'left',
  };

  return (
    <section className={clsx(styles['cancel-container'], styles[oemName])}>
      <Box
        sx={(theme) => ({
          margin: '1.5rem auto',
          paddingLeft: '1rem',
          paddingRight: '1rem',

          [theme.breakpoints.up('sm')]: {
            maxWidth: theme.breakpoints.values.sm,
          },
          [theme.breakpoints.up('md')]: {
            maxWidth: theme.breakpoints.values.md,
          },
        })}
      >
        <Slide {...slideProps} in={step === CancelFlow.SelectPackages}>
          <SelectPackagesToCancel
            selectedPackages={selectedPackages}
            onSelect={handleSelectPackage}
            Navigation={CancellationNavigation}
          />
        </Slide>

        <Slide {...slideProps} in={step === CancelFlow.AllBenefits}>
          <AllBenefits selectedPackages={selectedPackages} Navigation={CancellationNavigation} />
        </Slide>

        <Slide {...slideProps} in={step === CancelFlow.PackagePromotions}>
          <Promotions onSelect={handleSelectPromotion} Navigation={CancellationNavigation} />
        </Slide>

        <Slide {...slideProps} in={step === CancelFlow.NextBestOffer}>
          <NextBestOffer onSelect={handleSelectNextBestOffer} Navigation={CancellationNavigation} />
        </Slide>

        <Slide {...slideProps} in={step === CancelFlow.CancelPackages}>
          <CancelPackages onCancelReasonChange={handleSetCancelReason} Navigation={CancellationNavigation} />
        </Slide>

        <Slide {...slideProps} in={step === CancelFlow.PaymentMethods}>
          <Box>
            <Payment Navigation={ProfileButton} onContinue={onContinue} />
          </Box>
        </Slide>

        <Slide {...slideProps} in={step === CancelFlow.PreviewOrder}>
          <Box>
            <PreviewOrder Navigation={ProfileButton} onContinue={onContinue} />
          </Box>
        </Slide>

        <Slide {...slideProps} in={step === CancelFlow.LastMessage}>
          <Box>
            <LastMessage />
          </Box>
        </Slide>

        <Slide {...slideProps} in={step === CancelFlow.Error}>
          <Box>
            <Error />
          </Box>
        </Slide>
      </Box>
    </section>
  );
};

export default Cancellation;
