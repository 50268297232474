import {
  EligiblePackagesResponse,
  SearchEligiblePackagesRequest,
} from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { searchEligiblePackages } from '@cv/portal-cps-lib/subscription';
import { SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { SubscribedServices } from '@api/types';
import queryClient from '@api/queries/queryClient';
import { fetchVehicleDetails } from '@api/requests/vehicle';
import attachCapableServices from './attachCapableServices';

export type FetchEligiblePackagesOptions = {
  accessToken: string;
  tenantId: string;
  environment: Environment;
  userId: string;
  vehicleId: string;
  salesChannel?: SalesChannel;
  locale?: string;
  subscribedServices?: SubscribedServices[];
};

export type EligiblePackagesResult = {
  vehicleId: string;
  eligiblePackages: EligiblePackagesResponse[];
};

export const fetchEligiblePackages = async ({
  userId,
  vehicleId,
  environment,
  accessToken,
  tenantId,
  salesChannel,
  locale = 'en-US',
  subscribedServices,
}: FetchEligiblePackagesOptions): Promise<EligiblePackagesResult> => {
  const vehicleDetails = await fetchVehicleDetails({
    vehicleId,
    environment,
    accessToken,
    tenantId,
    locale,
    subscribedServices,
  });

  const request: SearchEligiblePackagesRequest = {
    data: {
      userId,
      vehicleId,
      salesChannel,
    },
    environment,
    headers: {
      Authorization: accessToken,
      'Accept-Language': locale,
      'CV-Tenant-Id': tenantId,
    },
  };

  return queryClient
    .fetchQuery(['getEligiblePackages'], () => searchEligiblePackages(request))
    .then((response) => ({
      eligiblePackages: attachCapableServices({
        packages: response.data,
        capableServices: vehicleDetails?.data?.capableServices,
      }),
      vehicleId,
    }))
    .catch(() => ({ eligiblePackages: [], vehicleId } as EligiblePackagesResult));
};
