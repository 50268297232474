import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { EventDataType, EventType, PageLoadStartedData } from './EventDataTypes';
import { CreatorFormProps } from '@components/EntryCreator/CreatorForm';
import React from 'react';
import { EventDataBuilder } from './EventBuilder';
import { AggregatedFlowContext } from '@app/components-lib/Router/flows/componentFlow/Types';

declare global {
  interface Window {
    appEventData: EventDataType[];
  }
}

window.appEventData = window.appEventData || [];
let analyticsEventbracketIsOpen = false;

export const isBracketedEvent = (event: EventType) => {
  return [EventType.UserProfileUpdatedEvent].includes(event);
};

export const openAnalyticsBracket = (
  context: AggregatedFlowContext,
  customProps?: Partial<PageLoadStartedData['page']>,
) => {
  if (analyticsEventbracketIsOpen) return;

  analyticsEventbracketIsOpen = true;
  sendAnalyticsEvent(new EventDataBuilder(EventType.PageLoadStartedEvent).withArgs(context, customProps));
};

export const closeAnalyticsBracket = () => {
  if (!analyticsEventbracketIsOpen) return;
  analyticsEventbracketIsOpen = false;
  sendAnalyticsEvent(new EventDataBuilder(EventType.PageLoadEvent).withArgs());
};

export const sendAnalyticsEvent = (data: EventDataType) => {
  //this was left on purpose, we should see which events we send to adobe analytics
  console.log('Send Analytics Event', data);
  window.appEventData.push(data);
};

export const useLifecycleAnalytics = (mountEvent: EventDataType, unmountEvent: EventDataType) => {
  useEffect(() => {
    sendAnalyticsEvent(mountEvent);
    return () => {
      sendAnalyticsEvent(unmountEvent);
    };
  }, []);
};

export const useOnScreen = (ref: RefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), []);

  useEffect(() => {
    ref.current && observer.observe(ref.current);
    return () => observer.disconnect();
  }, [observer, ref]);

  return isIntersecting;
};

type FormAnalyticsWrapperProps = {
  children: React.ReactNode;
  name?: string;
};
export const CommonFormAnalyticsWrapper = ({ children, name }: FormAnalyticsWrapperProps) => {
  const [formHasBeenShown, setFormHasBeenShown] = useState(false);
  const [formHasBeenEdited, setFormHasBeenEdited] = useState(false);
  const ref = useRef(null);
  const isOnScreen = useOnScreen(ref);
  useEffect(() => {
    if (formHasBeenShown) return;
    if (isOnScreen) {
      setFormHasBeenShown(true);
      name && sendAnalyticsEvent(new EventDataBuilder(EventType.FormViewedEvent).withArgs({ name }));
    }
  }, [isOnScreen, formHasBeenShown, name]);
  useEffect(() => {
    if (!formHasBeenEdited) return;
    name && sendAnalyticsEvent(new EventDataBuilder(EventType.FormStartedEvent).withArgs({ name }));
  }, [formHasBeenEdited, name]);

  return (
    <div ref={ref} onInput={() => setFormHasBeenEdited(true)}>
      {children}
    </div>
  );
};

export const withCreatorFormAnalytics = (CreatorFormComponent: (props: CreatorFormProps) => JSX.Element) => {
  return (props: CreatorFormProps) => {
    return (
      <CommonFormAnalyticsWrapper name={props.name}>
        <CreatorFormComponent {...props} />
      </CommonFormAnalyticsWrapper>
    );
  };
};
