import React from 'react';
import { Divider, List, ListItem, Skeleton } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import { useCancelLabels } from './CancelLabelsContext';
import { useCancelMachineContext } from './CancelSubscriptionStateMachineWrapper';
import PackageLine from './PackageLine';

const PreviewOrderList = () => {
  const { preview } = useCancelMachineContext();

  const {
    currencyLabel,
    importantCancellationInfo__Annual,
    importantCancellationInfo__Monthly,
    expires,
    renew,
    cancelEffectiveLabel,
    starts,
    moreDetailsLabel,
    defaultShortDescription,
    defaultLongDescription,
    servicesHierarchy,
    descriptions,
    assets,
  } = useCancelLabels();
  const previewIsLoading = useIsMutating({ mutationKey: ['previewOrder'] });

  if (previewIsLoading) {
    return <Skeleton variant="rectangular" animation="wave" width="100%" height="10vh" />;
  }

  const packageLineLabels = {
    cancelEffectiveLabel,
    currencyLabel,
    defaultLongDescription,
    defaultShortDescription,
    descriptions,
    expires,
    importantCancellationInfo__Annual,
    importantCancellationInfo__Monthly,
    renew,
    servicesHierarchy,
    starts,
    details: moreDetailsLabel,
  };

  const { subscribedPackages } = preview;

  return (
    <List>
      {subscribedPackages.map((pkg) => (
        <>
          <ListItem disablePadding key={pkg.packageId}>
            <PackageLine
              pkg={pkg}
              packageState={pkg.cancelReason ? 'cancel' : 'subscribe'}
              labels={packageLineLabels}
              assets={assets}
            />
          </ListItem>
          <Divider component="li" sx={{ mt: 2, mb: 2 }} />{' '}
        </>
      ))}
    </List>
  );
};

export default PreviewOrderList;
