import React, { forwardRef } from 'react';
import { Typography, InputLabel, Select, MenuItem, FormControl, Divider, Alert, Stack } from '@mui/material';
import { isAnnualTerm, isPaidPackage } from '@utils/subscriptionUtils';
import { useCancelLabels } from '@components/Cancellation/CancelLabelsContext';
import { useCancelMachineContext } from '@components/Cancellation/CancelSubscriptionStateMachineWrapper';
import NavigationProp from '@customTypes/NavigationProp';

import StepScreenHeader from '@components/Cancellation/StepScreenHeader';
import PreviewOrderList from '../PreviewOrderList';

type Props = {
  onCancelReasonChange: (reason: string) => void;
} & NavigationProp;

const CancelPackages = forwardRef(({ onCancelReasonChange, Navigation }: Props, _ref) => {
  const { selectedPackages, cancelReason } = useCancelMachineContext();

  const {
    importantCancellationInfo__Annual,
    importantCancellationInfo__Monthly,
    cancellationReasons,
    cancelPackagesHeader,
    reasonForCanceling,
    reasonForCancellingDescription,
    reasonForCancellingPostScript,
    reasonLabel,
  } = useCancelLabels();

  const isCancellingMonthlyPackages = selectedPackages?.some((sPkg) => isPaidPackage(sPkg) && !isAnnualTerm(sPkg));

  const cancelReasons = Object.keys(cancellationReasons).map((key) => ({
    value: key,
    label: cancellationReasons[key],
  }));

  return (
    <Stack spacing={4} component="div" ref={_ref as React.RefObject<HTMLDivElement>}>
      <StepScreenHeader>{cancelPackagesHeader}</StepScreenHeader>

      <PreviewOrderList />

      <Alert severity="warning">
        {isCancellingMonthlyPackages ? importantCancellationInfo__Monthly : importantCancellationInfo__Annual}
      </Alert>

      <Stack>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {reasonForCanceling}
        </Typography>
        <Typography>{reasonForCancellingDescription}</Typography>
        <FormControl
          sx={(theme) => ({
            mt: theme.spacing(1),
            mb: theme.spacing(1),

            width: '100%',
            [theme.breakpoints.up('sm')]: {
              width: '30%',
            },
          })}
          size="small"
        >
          <InputLabel id="reason-label">{reasonLabel}</InputLabel>
          <Select
            labelId="reason-label"
            id="reason"
            value={cancelReason}
            label="Reason"
            onChange={(e) => {
              onCancelReasonChange(e.target.value);
            }}
          >
            {cancelReasons.map(({ label, value }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider />
      </Stack>

      <Typography>{reasonForCancellingPostScript}</Typography>

      {Navigation && <Navigation />}
    </Stack>
  );
});

export default CancelPackages;
