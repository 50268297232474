import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { uniq } from 'lodash';
import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { CapablePackagesResponse } from '@customTypes/subscription';
import { getCookie } from '@utils/cookies';
import isPackageToShow from '@utils/isPackageToShow';
import { RootState } from '@app/reducers';
import { CapableServices } from '@api/types';
import { TokenTypes } from '@api/services/StoreService';
import { fetchSubscribedPackages } from '@api/requests/subscription/fetchSubscribedPackages';
import SubscriptionPackage from '@customTypes/SubscriptionPackage';

type HookOptions = {
  excludeDefaultPkgs?: boolean;
};

const initialData: CapablePackagesResponse = {
  subscribedPackages: [],
  vin: '',
  vehicleId: '',
};

export const useSubscribedPackages = ({ excludeDefaultPkgs = true }: HookOptions = {}) => {
  const vehicleId: string = useSelector(({ vehicleReducer }: RootState) => vehicleReducer.vehicle?.vehicleId || '');

  const userId: string = useSelector(({ userReducer }: RootState) => userReducer?.userId || '');
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const locale = useSelector(({ settingsReducer }: RootState) => settingsReducer?.locale || 'en-US');
  const tenantId = useSelector(({ accountReducer }: RootState) => accountReducer?.tenantId || '');
  const subscribedServices = useSelector(({ userReducer }: RootState) => userReducer?.subscribedServices || []);

  return useQuery({
    queryKey: ['subscribedPackages', vehicleId],
    queryFn: () =>
      fetchSubscribedPackages({
        accessToken,
        tenantId,
        userId,
        vehicleId,
        locale,
        excludeDefaultPkgs,
        subscribedServices,
      }),
    select: ({ vin, vehicleId, services, subscribedPackages }) => {
      return {
        vin,
        vehicleId,
        activeType: services?.some(
          (subscribed) =>
            subscribed.packageType === PackageType.Regular || subscribed.packageType === PackageType.Default,
        ),
        subscribedServiceIds: uniq(
          subscribedPackages
            ?.filter((subscribedPackage) => isPackageToShow(subscribedPackage as SubscriptionPackage))
            .flatMap((subscribedPackage) => {
              const serviceIds = subscribedPackage.serviceIds;
              const productsServiceIds = subscribedPackage.products.flatMap((product) => product.serviceIds);
              return [...serviceIds, ...productsServiceIds];
            }),
        ),
        packages: subscribedPackages || [],
      } as CapableServices;
    },
    enabled: !!vehicleId,
    placeholderData: initialData,
  });
};

export default useSubscribedPackages;
