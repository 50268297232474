import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCookie } from '@utils/cookies';
import { RootState } from '@app/reducers';
import { TokenTypes } from '@api/services/StoreService';
import { fetchEligiblePackages } from '@api/requests/subscription/fetchEligiblePackages';
import { EligiblePackagesResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { useConfig } from '@components/ConfigProvider';

export const useEligiblePackages = (salesChannel?: SalesChannel) => {
  const config = useConfig();

  const userId: string = useSelector(({ userReducer }: RootState) => userReducer?.userId || '');
  const vehicleId: string = useSelector(({ vehicleReducer }: RootState) => vehicleReducer.vehicle?.vehicleId || '');

  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const locale = useSelector(({ settingsReducer }) => settingsReducer?.locale);
  const tenantId = useSelector(({ accountReducer }) => accountReducer?.tenantId);
  const environmentEnum = config.getEnvironmentEnum();
  const subscribedServices = useSelector(({ userReducer }: RootState) => userReducer?.subscribedServices || []);

  return useQuery<EligiblePackagesResponse[]>({
    enabled: !!vehicleId,
    queryKey: ['eligiblePackages', salesChannel],
    queryFn: () =>
      fetchEligiblePackages({
        userId,
        vehicleId,
        environment: environmentEnum,
        accessToken,
        tenantId,
        salesChannel,
        locale,
        subscribedServices,
      }),
    placeholderData: { vehicleId, eligiblePackages: [] },
  });
};

export default useEligiblePackages;
