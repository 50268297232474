import React from 'react';
import { AssetObject } from '@utils/assets';
import { Box, Button, Collapse, Divider, Stack, SvgIconProps, Typography } from '@mui/material';
import { convertDuration, isTrialPackage } from '@app/components-lib';
import { EligiblePackageInfo } from '@customTypes/PackageInfo';
import clsx from 'clsx';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Image from '@app/components-lib/components/Image';
import SimpleProductItem from './SimpleProductItem';
import useToggle from '@hooks/useToggle';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  trialPackage: EligiblePackageInfo;
  labels: {
    activateTrial: string;
    expiresDateText: string;
    years: string;
    months: string;
    days: string;
    trial: string;
  };
  assets?: AssetObject;
  onSelect: (pkg: EligiblePackageInfo) => void;
}
const PackageTrialItem = (props: Props) => {
  const { trialPackage, labels, onSelect, assets = {} } = props;
  const [showProducts, setShowProducts] = useToggle(false);
  const { marketingName, variant } = trialPackage;
  const { activateTrial, expiresDateText, trial } = labels;
  const isTrial = isTrialPackage(variant);

  const expiresDateFormated = convertDuration(variant.startDate, variant.endDate, labels);

  const Chevron = (_props: SvgIconProps) =>
    showProducts ? <ExpandLessIcon {..._props} /> : <ExpandMoreIcon {..._props} />;

  return (
    <>
      <Grid container>
        <Grid sm={6}>
          <Stack>
            <Typography variant="h4" fontWeight="bold">
              {marketingName}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {`${expiresDateFormated} ${isTrial ? trial : ''}`}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {expiresDateText}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {trialPackage.shortDescription}
            </Typography>
          </Stack>
        </Grid>

        <Grid
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
          sm={6}
        >
          <Chevron
            sx={{ position: 'absolute', top: '10px', right: '10px' }}
            fontSize="large"
            onClick={() => setShowProducts(!showProducts)}
          />
          <Box>
            <Button
              variant={trialPackage?.isSelected ? 'contained' : 'outlined'}
              sx={{ justifySelf: 'flex-end' }}
              onClick={() => onSelect(trialPackage)}
            >
              {activateTrial}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={showProducts}>
        <Divider />
      </Collapse>
      <Collapse in={showProducts}>
        <Stack>
          {trialPackage.products.map((p) => (
            <SimpleProductItem
              product={p}
              key={p.marketingName}
              icon={<Image name={p.marketingName} assets={assets} size="22px" style={{ aspectRatio: '1 / 1' }} />}
            />
          ))}
        </Stack>
      </Collapse>
    </>
  );
};

export default PackageTrialItem;
