import { Alert } from '@mui/material';
import React from 'react';
import { useCancelMachineContext } from '../CancelSubscriptionStateMachineWrapper';

const Error = () => {
  const { error } = useCancelMachineContext();

  return (
    <>
      <Alert severity="warning">Something went wrong, please try again later.</Alert>
    </>
  );
};

export default Error;
