import { getVehicleById } from '@cv/portal-cps-lib/vehicle';
import { VehicleRequest } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { formatVehicleDetails } from '@api/formatters';
import { SubscribedServices } from '@api/types';
import queryClient from '@api/queries/queryClient';

export type FetchVehicleDetailsOptions = {
  accessToken: string;
  tenantId: string;
  vehicleId: string;
  environment: Environment;
  locale?: string;
  subscribedServices?: SubscribedServices[];
};

export const fetchVehicleDetails = ({
  vehicleId,
  environment,
  accessToken,
  tenantId,
  locale = 'en-US',
  subscribedServices = [],
}: FetchVehicleDetailsOptions) => {
  const request: VehicleRequest = {
    data: {
      id: vehicleId,
    },
    queryParams: {
      include: 'vehicle_services',
    },
    environment,
    headers: {
      Authorization: accessToken,
      'Accept-Language': locale,
      'CV-Tenant-Id': tenantId,
    },
  };

  return queryClient
    .fetchQuery(['getVehicleDetails', vehicleId], () => getVehicleById(request))
    .then((response) => ({
      data: formatVehicleDetails(response.data, subscribedServices),
    }));
};
