import React from 'react';
import { Formik, Form, FormikProps, FormikValues } from 'formik';
import { FormikInputField, FormikPhoneField } from '@components/FormikFields';
import * as yup from 'yup';
import { BaseSchema } from 'yup';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { fromPairs, omit } from 'lodash';

import { Button } from '@components-lib';
import Grid from '@components/Grid';
import InputSelect from '@components/InputSelect';
import InputMasked from '@components/InputMasked';
import InputCheckbox from '@components/InputCheckbox';
import OptSmsCheckbox from '@components/SmsCheckbox/OptSmsCheckbox';

import createYupFieldSchema, { createYupSchema } from '@utils/createYupSchema';
import createMask from '@utils/createMask';
import { markRemoved, isMarkedRemoved } from '@utils/form-removal-utils';

import ApiRequestPayload from '@customTypes/ApiRequestPayload';

import styles from './EntryCreator.module.css';
import FormikAddressField from '@components/FormikFields/FormikAddressField';
import { AnyObject, AssertsShape, TypeOfShape } from 'yup/lib/object';
import { withCreatorFormAnalytics } from '@lib-components/Analytics';

type FormFieldBase = {
  fieldName: string;
  fieldLabel?: string;
  fieldPayloadLabel?: string;
  fieldPlaceholder?: string;
  fieldValidation?: FormFieldValidation;
  hideWithField?: FormField;
};

type FormFieldDropdown = FormFieldBase & {
  fieldType: 'dropdown';
  fieldValues: Array<string>;
};

type FormFieldText = FormFieldBase & {
  fieldType: 'text';
};

type FormFieldCheckbox = FormFieldBase & {
  fieldType: 'checkbox';
  fieldCheckboxLabel: string;
};

type FormFieldTel = FormFieldBase & {
  fieldType: 'tel';
  fieldMask?: string;
  fieldPhoneCountry?: string;
};

type FormFieldStatic = FormFieldBase & {
  fieldType: 'static';
};

type EmptyObject = Record<string, never>;

type MinMaxMatchValidations = Array<
  | {
      type: 'min' | 'max' | 'matches';
      params: [number | string, string];
    }
  | EmptyObject
>;

export type FormField = FormFieldTel | FormFieldCheckbox | FormFieldText | FormFieldDropdown | FormFieldStatic;

type FormFieldValidation = {
  fieldReadOnly?: boolean;
  fieldReadOnlyError?: string;
  fieldRequired: boolean;
  fieldRequiredError?: string;
  fieldMin?: number;
  fieldMinError?: string;
  fieldMax?: number;
  fieldMaxError?: string;
  fieldMatches?: string;
  fieldMatchesError?: string;
  fieldValidateWithField?: FormFieldTel | FormFieldCheckbox | FormFieldText | FormFieldDropdown;
  fieldValidateWithFieldMatch: boolean;
  fieldValidateWithFieldError?: string;
  fieldValidateIsSelectedCheckbox?: boolean;
};

export interface CreatorFormProps {
  name?: string;
  title?: string;
  formFields?: Array<FormField>;
  initialValues?: ApiRequestPayload | FormikValues | null;
  formColumns?: '1' | '2' | '3';
  formLabelsDisplay?: boolean;
  disableSubmitOnInvalid?: boolean;
  validateOnBlur?: boolean;
  validateOnSubmit?: boolean;
  cancelLabel?: string;
  submitLabel?: string;
  onFormConfirm: (
    data: ApiRequestPayload | FormikValues,
    setFieldError?: (fieldName: string, message: string) => void,
    setSubmitting?: (isSubmitting: boolean) => void,
  ) => void;
  onFormClose: () => void;
  additionalSchemaObject?: yup.ObjectSchema<any, AnyObject, TypeOfShape<any>, AssertsShape<any>>;
  children?: React.ReactNode;
}

type Values = {
  [index: string]: string;
};

const getValueByAccountCountry = (field: string | undefined, accountCountry: string): string | undefined => {
  if (!field) return;

  const fieldValues = field.split('\n');
  const entriesByCountry = fieldValues
    .map((value) => value.split('::'))
    .filter(([country, value]) => country !== undefined && value !== undefined);

  if (entriesByCountry.length > 0) {
    const valueByCountry = fromPairs(entriesByCountry);
    return valueByCountry[accountCountry];
  }

  return fieldValues[0];
};

const createMinMaxMatchValidations = (
  fieldValidation: FormFieldValidation,
  accountCountry: string,
): MinMaxMatchValidations => {
  const { fieldMin, fieldMax, fieldMatches, fieldMinError, fieldMaxError, fieldMatchesError } = fieldValidation;

  const match = getValueByAccountCountry(fieldMatches, accountCountry);
  const matchError = getValueByAccountCountry(fieldMatchesError, accountCountry);

  return [
    fieldMin ? { type: 'min', params: [Number(fieldMin), fieldMinError || ''] } : {},
    fieldMax ? { type: 'max', params: [Number(fieldMax), fieldMaxError || ''] } : {},
    match ? { type: 'matches', params: [match, matchError || ''] } : {},
  ];
};

const fieldRequiredBasedOnOtherField = (
  fieldValidation: FormFieldValidation,
  minMaxMatchValidations: MinMaxMatchValidations,
) => {
  const { fieldValidateWithField } = fieldValidation;

  const isCheckbox = fieldValidateWithField?.fieldType === 'checkbox';

  const requiredBasedOnNonEmpty = (fieldName: string, schema: BaseSchema) => {
    const { fieldValidateWithFieldMatch, fieldValidateWithField, fieldValidateWithFieldError, fieldRequiredError } =
      fieldValidation;
    if (fieldValidateWithFieldMatch && fieldValidateWithField) {
      return schema
        .oneOf([yup.ref(fieldValidateWithField.fieldName)], fieldValidateWithFieldError || '')
        .required(fieldRequiredError || '');
    }
    return fieldName?.length > 0 ? schema?.required(fieldRequiredError || '') : schema;
  };

  const requiredBasedOnCheckbox = (fieldName: string, schema: BaseSchema) => {
    const { fieldValidateIsSelectedCheckbox = false, fieldRequiredError } = fieldValidation;
    // convert boolean to string because checkbox value is 'true' or 'false
    const isRequired = fieldName === fieldValidateIsSelectedCheckbox.toString();

    if (!isRequired) {
      return schema;
    }

    return createYupSchema(schema, {
      validationType: 'string',
      validations: [{ type: 'required', params: [fieldRequiredError || ''] }, ...minMaxMatchValidations],
    });
  };

  return (
    fieldValidateWithField && {
      type: 'when',
      params: [fieldValidateWithField.fieldName, isCheckbox ? requiredBasedOnCheckbox : requiredBasedOnNonEmpty],
    }
  );
};

const createYupFields = (
  formFields: Array<FormField>,
  accountCountry: string,
  initialValues: CreatorFormProps['initialValues'],
) => {
  let fieldValidationOptions: Array<object> = [];
  const generateFieldValidation = (field: FormikValues) => {
    if (field.componentType === 'portalAddressField') {
      return field?.formFields?.map(generateFieldValidation) || [];
    }
    if (field.fieldValidation) {
      const { fieldReadOnly, fieldRequired, fieldRequiredError } = field.fieldValidation;

      const isFieldRequired = fieldRequired && { type: 'required', params: [fieldRequiredError || ''] };

      const minMaxMatchValidations = createMinMaxMatchValidations(field.fieldValidation, accountCountry);

      const isFieldRequiredBasedOnOtherField = fieldRequiredBasedOnOtherField(
        field.fieldValidation,
        minMaxMatchValidations,
      );

      fieldValidationOptions = [isFieldRequiredBasedOnOtherField || isFieldRequired || {}];

      if (!isFieldRequiredBasedOnOtherField) {
        fieldValidationOptions = fieldValidationOptions.concat(minMaxMatchValidations);
      }

      // if field got read only validation then all others are not needed (you cannot edit this field)
      if (fieldReadOnly) {
        // this validation needs to be done manually
        fieldValidationOptions = [{ type: 'notRequired', params: [null] }];
      }
    } else {
      fieldValidationOptions = [{ type: 'notRequired', params: [null] }];
    }

    if (initialValues?.[field.fieldName] === null) {
      fieldValidationOptions.push({ type: 'nullable', params: [] });
    }

    return {
      id: field.fieldName,
      label: field.fieldLabel,
      placeholder: field.fieldLabel,
      type: field.fieldType,
      validationType: field.fieldType === 'checkbox' ? 'bool' : 'string',
      validations: fieldValidationOptions,
    };
  };
  return formFields.map(generateFieldValidation).flat();
};

const CreatorForm = ({
  name,
  title,
  formFields = [],
  initialValues = null,
  formColumns = '1',
  formLabelsDisplay = true,
  disableSubmitOnInvalid = true,
  validateOnBlur = true,
  validateOnSubmit,
  cancelLabel,
  submitLabel,
  onFormConfirm,
  onFormClose,
  additionalSchemaObject,
  children,
}: CreatorFormProps): JSX.Element => {
  const { vehicle } = useSelector(({ vehicleReducer }) => vehicleReducer);
  const { registrationCountry: accountCountry = 'USA' } = vehicle || {};

  let initialValuesData = {};
  const initValuesData = (_formFields = []) => {
    _formFields.forEach((field) => {
      if (field.componentType === 'portalAddressField') {
        return initValuesData(field.formFields);
      }
      initialValuesData[field.fieldName] = '';
    });
  };

  if (!initialValues) {
    initValuesData(formFields);
  }

  if (initialValues) {
    Object.entries(initialValues).forEach(({ key, value }) => {
      initialValues[key] = value ?? '';
    });
  }

  const yupFields = createYupFields(formFields, accountCountry, initialValues || initialValuesData);
  const yupSchema = yupFields.reduce(createYupFieldSchema, {});
  const validationSchema = yup
    .object()
    .shape(yupSchema)
    .concat(additionalSchemaObject || yup.object());

  const payloadLabels = formFields.reduce(
    (labels, field) =>
      !!field.fieldPayloadLabel && !!field.fieldLabel
        ? { ...labels, [field.fieldPayloadLabel]: field.fieldLabel }
        : labels,
    {},
  );

  const appendAdditional = (values: FormikValues) =>
    Object.assign({}, values, initialValues?._id && { _id: initialValues._id }, payloadLabels);

  const parseEmptyValues = (values: FormikValues) => {
    // undefined values are unchanged since the form was opened
    // "" values were actually removed by the user
    const emptyFields = Object.keys(values).filter((key) => values[key] === '');
    if (emptyFields.length) {
      const removedValues = {};
      emptyFields.forEach((field) => {
        removedValues[field] = markRemoved(initialValues?.[field] || '');
      });
      return Object.assign({}, values, removedValues);
    }
    return values;
  };

  const validationOptions = validateOnSubmit
    ? { validateOnBlur: false, validateOnChange: false }
    : { validateOnBlur, validateOnChange: true };

  return (
    <div className={styles.CreatorForm}>
      {title && <h3>{title}</h3>}

      <Formik
        key={name}
        initialValues={initialValues || initialValuesData}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
          onFormConfirm(appendAdditional(omit(parseEmptyValues(values), [undefined])), setFieldError, setSubmitting);
        }}
        validationSchema={validationSchema}
        {...validationOptions}
      >
        {(props: FormikProps<Values>) => {
          const isDisabled = () => {
            if (validateOnSubmit) {
              return false;
            }

            return disableSubmitOnInvalid && (!props.dirty || !props.isValid);
          };

          return (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid className={styles['grid']} columns={formColumns}>
                  {formFields.map((content, i) => {
                    if (content.componentType === 'portalAddressField') {
                      return (
                        <FormikAddressField key={`${i}-${content.componentType}-${content.addressType}`} {...content} />
                      );
                    }
                    const {
                      fieldName,
                      fieldType,
                      sort,
                      fieldLabel,
                      fieldPlaceholder,
                      fieldCheckboxLabel,
                      fieldValues,
                      fieldMask,
                      fieldValidation,
                      hideWithField,
                      fieldPhoneCountry,
                    }: FormikValues = content;
                    const key = `${i}-${fieldName}`;

                    const hidingFieldName = hideWithField?.fieldName;
                    if (hidingFieldName && props.values[hidingFieldName]) {
                      return null;
                    }

                    // Static text
                    if (fieldType === 'static') {
                      /* Trim needed for Empty Static Field, see: PORTAL Form Field  */
                      const isLabelEmpty = fieldLabel.trim().length === 0;
                      const initialValue = initialValues?.[fieldName] || '';
                      const fieldValue = isMarkedRemoved(initialValue) ? '' : initialValue;
                      return (
                        <div
                          className={clsx(styles['field-static'], { [styles['field-static-empty']]: isLabelEmpty })}
                          key={key}
                        >
                          <div className={styles['field-static--label']}>{fieldLabel}</div>
                          <div className={styles['field-static--value']}>{fieldValue || fieldPlaceholder}</div>
                        </div>
                      );
                    }

                    const isDropdown = fieldType === 'dropdown';
                    const isPhone = fieldType === 'tel';
                    const fieldLabelValue = formLabelsDisplay ? fieldLabel : '';

                    if (isDropdown) {
                      if (sort) {
                        fieldValues.sort();
                      }

                      const options = fieldValues.map((option: string) => {
                        const [value, label] = option.split('::');
                        if (label) {
                          return { value, label };
                        }
                        return { value: option, label: option };
                      });

                      return (
                        <FormikInputField
                          name={fieldName}
                          label={fieldLabelValue}
                          type={fieldType}
                          placeholder={fieldPlaceholder}
                          key={key}
                          InputComponent={InputSelect}
                          onChange={() => undefined}
                          options={options}
                          styleType="primary"
                        />
                      );
                    }

                    if (!isDropdown) {
                      if (isPhone) {
                        const smsField = formFields.find(
                          (field) => field.fieldName === 'optSms' && field.fieldType === 'checkbox',
                        );

                        return (
                          <div className={styles[`${fieldName}--field`]}>
                            <FormikPhoneField
                              name={fieldName}
                              label={fieldLabelValue}
                              placeholder={fieldPlaceholder}
                              key={key}
                              fieldPhoneCountry={fieldPhoneCountry}
                              form={{
                                errors: props.errors,
                                handleBlur: props.handleBlur,
                                setFieldValue: props.setFieldValue,
                                touched: props.touched,
                              }}
                            />
                            {smsField && fieldName === 'primaryPhone' && (
                              <OptSmsCheckbox name={smsField.fieldName} isSubmitting={props.isSubmitting} />
                            )}
                          </div>
                        );
                      }

                      if (fieldMask) {
                        const arrayRegex = createMask(fieldMask);

                        return (
                          <FormikInputField
                            name={fieldName}
                            type={fieldType}
                            label={fieldLabelValue}
                            placeholder={fieldPlaceholder}
                            key={key}
                            mask={arrayRegex}
                            InputComponent={InputMasked}
                            showMaskOnHover
                          />
                        );
                      }

                      if (fieldType === 'checkbox') {
                        return (
                          <FormikInputField
                            name={fieldName}
                            type={fieldType}
                            label={fieldLabelValue}
                            placeholder={fieldCheckboxLabel || fieldPlaceholder}
                            key={key}
                            InputComponent={InputCheckbox}
                          />
                        );
                      }

                      return (
                        <FormikInputField
                          name={fieldName}
                          type={fieldType}
                          label={fieldLabelValue}
                          placeholder={fieldPlaceholder}
                          key={key}
                          minLength={fieldValidation?.fieldMin}
                          maxLength={fieldValidation?.fieldMax}
                          readOnly={fieldValidation?.fieldReadOnly}
                          readOnlyError={fieldValidation?.fieldReadOnlyError}
                        />
                      );
                    }
                  })}
                </Grid>
                {children}
              </Form>
              <div className={styles.ButtonContainer}>
                <Button variant="outlined" onClick={onFormClose}>
                  {cancelLabel}
                </Button>
                <Button
                  variant="contained"
                  className={styles['Button-save']}
                  disabled={props.isSubmitting || isDisabled()}
                  onClick={props.submitForm}
                  title={props.isSubmitting ? 'Form is being submitted' : 'Submit form'}
                >
                  {submitLabel}
                </Button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default withCreatorFormAnalytics(CreatorForm);
