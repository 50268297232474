import React, { useEffect, useMemo, useState } from 'react';

import { AggregatedFlowContext } from '@components-lib/Router/flows/componentFlow/Types';
import {
  EventDataBuilder,
  EventType,
  closeAnalyticsBracket,
  openAnalyticsBracket,
  sendAnalyticsEvent,
} from '@lib-components/Analytics';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ContentMap } from '../..';
import { EligiblePackageInfo, PackageSubscription, SubscribedPackageInfo } from '../../Types';
import { getPaidPackage } from '../../utils';
import ViewPackages, { ViewPackagesProps } from './ViewPackages';
import { extractVariants } from './utils';
import { Divider, useMediaQuery, useTheme } from '@mui/material';
import { findIndex } from 'lodash';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}
function PackagesListTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`packages-tabpanel-${index}`}
      aria-labelledby={`packages-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export type PaidPackagesProps = {
  packages: EligiblePackageInfo[];
  removePaidPackages: () => void;
  termsLabel: ContentMap;
  tenantId: string;
  subscribedPackages: SubscribedPackageInfo[];
  packageSubscriptions: PackageSubscription[];
  flowMachineContext: AggregatedFlowContext;
} & Omit<ViewPackagesProps, 'packages'>;

const PaidPackages = ({
  packages,
  tenantId,
  termsLabel,
  subscribedPackages,
  packageSubscriptions,
  removePaidPackages,
  flowMachineContext,
  ...viewPackagesProps
}: PaidPackagesProps) => {
  // Extract package variants
  const variants = React.useMemo(
    () => extractVariants(subscribedPackages, packages, tenantId),
    [subscribedPackages, packages, tenantId],
  );

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.up('md'));

  const selectedVariantIndex = () => {
    const current = getPaidPackage(packageSubscriptions, subscribedPackages);
    const variantKeys = Object.keys(variants);
    const isTermInKeys = !!current && variantKeys.includes(String(current.variant.initialTerm));
    if (isTermInKeys) {
      return String(variantKeys[findIndex(variantKeys, (key) => key === String(current.variant.initialTerm))]);
    }
    return String(variantKeys[variantKeys.length - 1]);
  };

  const [selectedTab, setSelectedTab] = useState<string>(selectedVariantIndex());

  const packagesByVariant = useMemo(() => Object.entries(variants), [variants]);

  if (Object.keys(variants).length === 0) {
    return null;
  }

  const fireListingEvent = () => {
    const currentTab = packagesByVariant.findIndex(([key]) => key === selectedTab);
    openAnalyticsBracket(flowMachineContext);
    sendAnalyticsEvent(
      new EventDataBuilder(EventType.ProductListingDisplayedEvent).withArgs(packagesByVariant[currentTab][1]),
    );
    closeAnalyticsBracket();
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(fireListingEvent, [selectedTab]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    // clear selections
    removePaidPackages();
    // select tab
    setSelectedTab(newValue);
  };

  return (
    <Container>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="packages variants tabs"
        variant={isMobileView ? 'standard' : 'fullWidth'}
      >
        {packagesByVariant.map(([key]) => (
          <Tab label={termsLabel[key]} value={key} key={key} disableFocusRipple />
        ))}
      </Tabs>
      <Divider />
      {packagesByVariant.map(([key, value]) => (
        <PackagesListTabPanel value={selectedTab} index={key} key={key}>
          <ViewPackages
            {...viewPackagesProps}
            subscribedPackages={subscribedPackages}
            packageSubscriptions={packageSubscriptions}
            packages={value}
          />
        </PackagesListTabPanel>
      ))}
    </Container>
  );
};

export default PaidPackages;
