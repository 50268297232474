import { usePortalTargetContext } from '@components/PortalTargetComponent/PortalTargetContext';
import { BrandNamesFilterGroup } from '@customTypes/BrandNamesFilterGroup';
import useLabels from '@hooks/useLabels';
import usePreferencesSelector from '@redux/selectors/preferences';
import { aggregateContent } from '@utils/contentful';
import filterTargetContent from '@utils/filterTargetContent';
import { ContentfulLabelType, findDictionary } from '@utils/labels';
import {
  OFFER_DETAILS_DICT_KEY,
  PACKAGE_PER_TERM_DICT_KEY,
  PACKAGE_TERMS_DICT_KEY,
  PACKAGE_UPSELL_HEADERS_DICT_KEY,
  SECRET_QUESTIONS_LIST,
  USER_PROFILE_PAGE,
  INFORMATION_ENROLLMENT_PAGE,
  INFORMATION_SUBSCRIPTION_PAGE,
  AUTH_CODE_FORM,
  PREFERRED_LANGUAGES_DICT_KEY,
  CARD_TYPES,
  MEXICO_STATES_DICT_KEY,
} from './constants';
import { get } from '@components/GlobalPreferences';
import config from '@config/config';
import { CFDIWebContent, SubscriptionProps } from '@components-lib';

import {
  AddOnsWebContent,
  CommonWebContent,
  DiscountWebContent,
  EligiblePackagesWebContent,
  OfferDetails,
  PackagesWebContent,
  PromoPackagesWebContent,
  SubscribedPackagesWebContent,
  TermsAndConditionsWebContent,
  TrialPackagesWebContent,
  UserProfileWebContent,
  InformationPageContent,
  WifiWebContent,
  DeclineTerms,
  AddressFormWebContent,
  AuthCodeFormContent,
  NissanCpoWebContent,
} from '@components-lib';
import { generateAssetsObjects, ServerAsset } from '@utils/assets';
import { useSelector } from 'react-redux';
import { RootState } from '@app/reducers';
import { AccountData, useApi } from '@api';
import { omitRecursivelyBy } from '@utils/objects';
import { isNil } from 'lodash';
import { FormField } from '@components/EntryCreator/CreatorForm';

const isInvalidValue = (v: unknown) => isNil(v) || v === '';

type Labels = {
  designatedRefundDealerMessage: string;
  designatedRefundDealerMessageHeader: string;
  promoCodeError: string;
  ccRequiredCopyBlock: string;
  ccNotRequiredCopyBlock: string;
  noPaymentsSubscribeLink: string;
  noPaymentsHeader: string;
  phoneRequiredError: string;
  phoneError: string;
  back: string;
  ok: string;
  continue: string;
  currentSubscription: string;
  enterNewCard: string;
  useCardOnFile: string;
  applePayMethodLabel: string;
  paypalPayMethodLabel: string;
  expires: string;
  halfYearly: string;
  month: string;
  monthly: string;
  years: string;
  months: string;
  days: string;
  subscription: string;
  paymentInfo: string;
  paymentTermsAndConditions: string;
  applePayDisplayName: string;
  quarterly: string;
  renew: string;
  currentPackage: string;
  saveAndContinue: string;
  skipAndContinue: string;
  inactivePackageHeader: string;
  premiumTrialPackageHeader: string;
  selectOrPlusTrialPackageHeader: string;
  conveniencePaidPackageHeader: string;
  securityPaidPackageHeader: string;
  premiumPaidPackageHeader: string;
  packagesDescription: string;
  year: string;
  yearly: string;
  noPackagesAvailable: string;
  packageUpgrade: string;
  addServices: string;
  packageLabel: string;
  expirationLabel: string;
  cancel: string;
  cancelSubscriptions: string;
  reviewOrder: string;
  paymentMethod: string;
  edit: string;
  billingAddress: string;
  cardNumberEnding: string;
  paymentError: string;
  paymentBillChargeError: string;
  paymentServerErrorMessage: string;
  paypalAgreementTermsErrorMsg: string;
  orderSummary: string;
  starts: string;
  cancelEffective: string;
  refund: string;
  refundTaxLabel: string;
  refundDueLabel: string;
  subscriptionSummary: string;
  subTotal: string;
  packagePrice: string;
  totalTax: string;
  totalAmount: string;
  discount: string;
  paymentDate: string;
  subscribeNow: string;
  prorationLegal: string;
  taxLegalPayNow: string;
  taxLegalPayLater: string;
  add: string;
  added: string;
  addOnHeader: string;
  addOnDescription: string;
  required: string;
  cardNumber: string;
  expiration: string;
  cardholderName: string;
  addressLineOne: string;
  addressLineTwo: string;
  zipCode: string;
  thisIsRequiredField: string;
  invalidInput: string;
  orderSuccessful: string;
  successOrderMsg: string;
  successOrderEmail: string;
  contactCustomerCareHeader: string;
  contactCustomerCareBody: string;
  currencyLabel: string;
  privacyPolicy: string;
  trialPackageLegalText: string;
  paidPackageLegalText: string;
  paymentTerms: string;
  additionalPaymentTerms: string;
  reviewOrderTerms: string;
  done: string;
  PROFILE: string;
  noThanksContinueTrialEnrollLabel: string;
  trialPackageHeader?: string;
  trialPackageSubHeader?: string;
  trialOptionHeader?: string;
  trialEligibleHeader: string;
  trialEligibleSubheader: string;
  baseTrialLabel: string;
  extendedTrialLabel: string;
  trialEligibleDescription: string;
  packageSelectionTrialTermsDisclaimer: string;
  trialLabel?: string;
  legalText?: string;
  discountHeader?: string;
  offerDetailsHeader?: string;
  offerDetailsOneMonth?: string;
  offerDetailsTwelveMonths?: string;
  regularlyLabel?: string;
  nowLabel?: string;
  details?: string;
  paidInFullLabel?: string;
  offerDetailsLabel?: string;
  packageDetailsLabel?: string;
  closeButtonLabel?: string;
  getThisDealLabel?: string;
  promoCodeLabel?: string;
  saveMoreLabel?: string;
  discountPackageHeader?: string;
  discountPackageDescription?: string;
  rrsPromoCriteria: string;
  subscriberPromoCriteria: string;
  discountLegalText?: string;
  promotionExpiryHeader?: string;
  promotionExpiryDescription?: string;
  userProfileHeader: string;
  userProfileTitle: string;
  userProfileErrorMessage: string;
  firstNameInputLabel: string;
  firstNamePlaceholder: string;
  firstNameValidationError: string;
  lastNameInputLabel: string;
  lastNamePlaceholder: string;
  lastNameValidationError: string;
  emailInputLabel: string;
  emailPlaceHolder: string;
  emailValidationError: string;
  phoneNumberInputLabel: string;
  phoneNumberPlaceholder: string;
  phoneNumberValidationError: string;
  mobileNetworkOperatorLabel: string;
  addressLineOneInputLabel: string;
  addressLineOnePlaceholder: string;
  addressLineOneValidationError: string;
  addressLineTwoInputLabel: string;
  addressLineTwoPlaceholder: string;
  zipInputLabel: string;
  zipPlaceholder: string;
  zipValidationError: string;
  inputsDisplayed: string;
  requiredInputs: string;
  appLinksHeaderLabel: string;
  appLinksDescriptionLabelAlfa: string;
  appLinksDescriptionLabelJeep: string;
  appLinksDescriptionUconnectLabel: string;
  appLinksAppleAlfaUrl: string;
  appLinksGoogleAlfaUrl: string;
  appLinksAppleUconnectUrl: string;
  appLinksGoogleUconnectUrl: string;
  appLinksGoogleJeep: string;
  appLinksAppleJeep: string;
  updateEmergencyContacts: string;

  termsAndConditionsHeader?: string;
  termsAndConditionsDesc?: string;
  effectiveAsOf?: string;
  acceptTermsConfirmationMsg?: string;
  acceptTerms?: string;
  declineTerms?: string;
  termsAndConditionsErrorMessage?: string;

  declineTermsHeader?: string;
  declineTermsDescription?: string;
  declineTermsMessage?: string;
  declineTermsLabel?: string;
  continueEnrollLabel?: string;

  attWifiHeaderLabel?: string;
  attWifiDescriptionLabel?: string;
  attWifiActivateLabel?: string;
  attWifiHeaderSuccessLabel?: string;
  attWifiDescriptionSuccessLabel?: string;
  attWifiActivateSuccessLabel?: string;

  streetPlaceholder: string;
  streetTwoPlaceholder: string;
  cityPlaceholder: string;
  countryPlaceholder: string;
  postalCodePlaceholder: string;
  submitButtonLabel: string;
  applyButtonLabel: string;
  billingAndMailingSameLabel: string;
  statePlaceholder: string;
  streetLabel: string;
  streetTwoLabel: string;
  cityLabel: string;
  countryLabel: string;
  postalCodeLabel: string;
  stateLabel: string;
  streetError: string;
  cityError: string;
  countryError: string;
  postalCodeError: string;
  stateError: string;
  mailingAddressLabel: string;
  billingAddressLabel: string;
  title: string;
  taxLabel?: string;
  totalLabel?: string;
  taxDescription?: string;
  renewalInfo?: string;

  yes?: string;
  no?: string;
  delete?: string;
  save?: string;
  newPaymentInfo?: string;
  creditCardInfo: string;
  addPaymentMethod?: string;
  replacePaymentMethod?: string;
  fontStyle?: string;
  cardTypes?: {
    [key: string]: string;
  };
  mexicoStates?: {
    [key: string]: string;
  };
  nissanCpoMobileOperators: string;
  nissanCpoStates: string;
  immediatelyProceed?: string;
  showTaxSplit?: string;
  deletePaymentMethodConfirmation?: string;

  errorDefaultDescription?: string;
  errorDefaultTitle?: string;
  errorMaritzDescription?: string;
  errorMaritzTitle?: string;
  errorPooTitle?: string;

  usoCFDILabel?: string;
  usoCFDILabelError?: string;
  usoCFDIPlaceholder?: string;
  usoCFDIOptions?: string;

  regimenFiscalReceptorLabel?: string;
  regimenFiscalReceptorLabelError?: string;
  regimenFiscalReceptorPlaceholder?: string;
  regimenFiscalReceptorOptions?: string;

  postalCodeCFDISameAsHomeZipCodeLabel?: string;
  postalCodeCFDILabel?: string;
  postalCodeCFDILabelError?: string;
  postalCodeCFDIPlaceholder?: string;
};

export const useFormSubscriptionProps = () => {};

export const useGenerateSubscriptionLabels = (
  contentfulLabels: ContentfulLabelType[],
  filterGroup?: BrandNamesFilterGroup,
) => {
  const preferences = usePreferencesSelector();

  const target = usePortalTargetContext();
  const targetLabels = contentfulLabels.map(
    (labelsDictionary) =>
      ({
        ...labelsDictionary,
        content: filterTargetContent(labelsDictionary.content, target),
      } as ContentfulLabelType),
  );

  const pkgTermLabels = useLabels(findDictionary(targetLabels, PACKAGE_TERMS_DICT_KEY));
  const pkgPerTermLabels = useLabels(findDictionary(targetLabels, PACKAGE_PER_TERM_DICT_KEY));
  const offerDetailLabels = useLabels(findDictionary(targetLabels, OFFER_DETAILS_DICT_KEY));
  const preferredLanguagesLabels = useLabels(findDictionary(targetLabels, PREFERRED_LANGUAGES_DICT_KEY));
  const packageUpsellHeaderLabels = useLabels(findDictionary(targetLabels, PACKAGE_UPSELL_HEADERS_DICT_KEY));
  const informationEnrollmentPageLabels = useLabels(findDictionary(targetLabels, INFORMATION_ENROLLMENT_PAGE));
  const informationSubscriptionPageLabels = useLabels(findDictionary(targetLabels, INFORMATION_SUBSCRIPTION_PAGE));
  const userProfilePageLabels = useLabels(findDictionary(targetLabels, USER_PROFILE_PAGE));
  const secretQuestionsListLabels = useLabels(findDictionary(targetLabels, SECRET_QUESTIONS_LIST));
  const authCodeFormListLabels = useLabels(findDictionary(targetLabels, AUTH_CODE_FORM));
  const cardTypes = useLabels(findDictionary(targetLabels, CARD_TYPES));
  const mexicoStateStatesListLabels = useLabels(findDictionary(targetLabels, MEXICO_STATES_DICT_KEY));

  const labelsCollection = useLabels(aggregateContent(targetLabels), filterGroup);
  const labels = labelsCollection.getAllPrimaries() as Labels;
  const additionalPaymentTerms = labelsCollection.get('paymentTerms')?.secondary;

  const subscribedPackagesWebContent: SubscribedPackagesWebContent = {
    expiresLabel: labels.expires,
    renewLabel: labels.renew,
    subscriptionLabel: labels.subscription,
    startsLabel: labels.starts,
    cancelEffectiveLabel: labels.cancelEffective,
  };

  const nissanCpoWebContent: NissanCpoWebContent = {
    mobileNetworkOperatorLabel: labels.mobileNetworkOperatorLabel,
    nissanCpoMobileOperators: labels.nissanCpoMobileOperators,
    nissanCpoStates: labels.nissanCpoStates,
  };

  const eligiblePackagesWebContent: EligiblePackagesWebContent = {
    termsLabel: pkgTermLabels.getAllPrimaries(),
    perTermsLabel: pkgPerTermLabels.getAllPrimaries(),
    noPackagesAvailableLabel: labels.noPackagesAvailable,
    packageUpgradeText: labels.packageUpgrade,
  };

  // TODO: To handle mandatory/optional types based on the tenant
  const offerDetails: OfferDetails = {
    header: labels.offerDetailsHeader,
    offerDetail: offerDetailLabels.getAllPrimaries(),
  };

  const discountWebContent: DiscountWebContent = {
    header: labels.discountHeader,
    offerDetails: offerDetails,
    regularlyLabel: labels.regularlyLabel,
    nowLabel: labels.nowLabel,
    paidInFullLabel: labels.paidInFullLabel,
    offerDetailsLabel: labels.offerDetailsLabel,
    getThisDealLabel: labels.getThisDealLabel,
    promoCodeLabel: labels.promoCodeLabel,
    saveMoreLabel: labels.saveMoreLabel,
    promoCodeErrorLabel: labels.promoCodeError,
  };

  const trialPackagesWebContent: TrialPackagesWebContent = {
    header: labels.trialPackageHeader,
    subHeader: labels.trialPackageSubHeader,
    trialLabel: labels.trialLabel,
    trialOptionHeader: labels.trialOptionHeader,
    trialEligibleHeader: labels.trialEligibleHeader,
    trialEligibleSubheader: labels.trialEligibleSubheader,
    baseTrialLabel: labels.baseTrialLabel,
    extendedTrialLabel: labels.extendedTrialLabel,
    packageSelectionTrialTermsDisclaimer: labels.packageSelectionTrialTermsDisclaimer,
    trialEligibleDescription: labels.trialEligibleDescription,
    legalText: labels.legalText,
    ccRequiredCopyBlock: labels.ccRequiredCopyBlock,
    ccNotRequiredCopyBlock: labels.ccNotRequiredCopyBlock,
  };

  const promoPackagesWebContent: PromoPackagesWebContent = {
    header: labels.discountPackageHeader,
    description: labels.discountPackageDescription,
    discount: discountWebContent,
    rrsPromoCriteria: labels.rrsPromoCriteria,
    subscriberPromoCriteria: labels.subscriberPromoCriteria,
    legalText: labels.discountLegalText,
    expiryHeader: labels.promotionExpiryHeader,
    expiryDescription: labels.promotionExpiryDescription,
    packageDetailsLabel: labels.packageDetailsLabel,
  };
  const packagesWebContent: PackagesWebContent = {
    packagesHeaderLabel: packageUpsellHeaderLabels.getAllPrimaries(),
    packagesDescription: labels.packagesDescription,
    currentSubscriptionLabel: labels.currentSubscription,

    packagePrefixLabel: preferences.serviceName,
    currentPackageLabel: labels.currentPackage,
    addServicesButtonLabel: labels.addServices,
    packageLabel: labels.packageLabel,
    expirationLabel: labels.expirationLabel,

    cancelSubscriptions: labels.cancelSubscriptions,
    trialPackageLegalText: labels.trialPackageLegalText || '',
    packageLegalText: labels.paidPackageLegalText,

    immediatelyProceed: labels.immediatelyProceed === 'enabled',

    subscribedPackagesWebContent: subscribedPackagesWebContent,
    eligiblePackagesWebContent: eligiblePackagesWebContent,
  };

  const addOnsWebContent: AddOnsWebContent = {
    addOnHeaderLabel: labels.addOnHeader,
    addOnDescription: labels.addOnDescription,
  };

  const commonWebContent: CommonWebContent = {
    yesLabel: labels.yes,
    noLabel: labels.no,
    addButtonLabel: labels.add,
    addedButtonLabel: labels.added,
    applyButtonLabel: labels.applyButtonLabel,
    yearsLabel: labels.years,
    monthsLabel: labels.months,
    daysLabel: labels.days,
    backButtonLabel: labels.back,
    editLinkLabel: labels.edit,
    okButtonLabel: labels.ok,
    cancelLinkLabel: labels.cancel,
    continueButtonLabel: labels.continue,
    closeButtonLabel: labels.closeButtonLabel,
    currencyLabel: labels.currencyLabel,
    skipAndContinueButtonLabel: labels.skipAndContinue,
    saveAndContinueButtonLabel: labels.saveAndContinue,
    saveLabel: labels.save,
    deleteLabel: labels.delete,
    detailsLabel: labels.details,
    errorDefaultDescription: labels.errorDefaultDescription,
    errorDefaultTitle: labels.errorDefaultTitle,
    errorMaritzDescription: labels.errorMaritzDescription,
    errorMaritzTitle: labels.errorMaritzTitle,
    errorPooTitle: labels.errorPooTitle,

    shouldEnableApplePay: preferences.shouldEnableApplePay === 'true',
    shouldEnablePayPal: preferences.shouldEnablePayPal === 'true',
    shouldIncludeTax: preferences.shouldIncludeTax === 'true',
    productsToHide: preferences.productsToHide?.split(','),

    preferredLanguages: preferredLanguagesLabels.getAllPrimaries(),
  };

  const userProfileWebContentLabels = userProfilePageLabels.getAllPrimaries();
  const userProfileWebContent: UserProfileWebContent = {
    userProfileHeader: userProfileWebContentLabels.userProfileHeader,
    userProfileTitle: userProfileWebContentLabels.userProfileTitle,
    firstNameInputLabel: userProfileWebContentLabels.firstNameInputLabel,
    firstNamePlaceholder: userProfileWebContentLabels.firstNameInputLabel,
    firstNameValidationError: userProfileWebContentLabels.firstNameValidationError,
    lastNameInputLabel: userProfileWebContentLabels.lastNameInputLabel,
    lastNamePlaceholder: userProfileWebContentLabels.lastNameInputLabel,
    lastNameValidationError: userProfileWebContentLabels.lastNameValidationError,
    emailInputLabel: userProfileWebContentLabels.emailInputLabel,
    emailPlaceHolder: userProfileWebContentLabels.emailInputLabel,
    emailValidationError: userProfileWebContentLabels.emailValidationError,
    emailValidation: userProfileWebContentLabels.emailValidation,

    passwordInputLabel: userProfileWebContentLabels.passwordInputLabel,
    passwordPlaceholder: userProfileWebContentLabels.passwordPlaceholder,
    passwordMinValueError: userProfileWebContentLabels.passwordMinValueError,
    passwordMaxValueError: userProfileWebContentLabels.passwordMaxValueError,
    passwordUpperCaseError: userProfileWebContentLabels.passwordUpperCaseError,
    passwordLowerCaseError: userProfileWebContentLabels.passwordLowerCaseError,
    passwordOneDigitError: userProfileWebContentLabels.passwordOneDigitError,
    passwordSpecialCharError: userProfileWebContentLabels.passwordSpecialCharError,
    passwordRepeatingCharError: userProfileWebContentLabels.passwordRepeatingCharError,
    passwordSequentialCharError: userProfileWebContentLabels.passwordSequentialCharError,
    passwordNoSpacesError: userProfileWebContentLabels.passwordNoSpacesError,
    passwordRequiredError: userProfileWebContentLabels.passwordRequiredError,
    passwordValidationError: userProfileWebContentLabels.passwordValidationError,
    confirmPasswordInputLabel: userProfileWebContentLabels.confirmPasswordInputLabel,
    confirmPasswordPlaceholder: userProfileWebContentLabels.confirmPasswordPlaceholder,
    confirmPasswordError: userProfileWebContentLabels.confirmPasswordError,
    pinInputLabel: userProfileWebContentLabels.pinInputLabel,
    pinPlaceholder: userProfileWebContentLabels.pinPlaceholder,
    pinRequiredError: userProfileWebContentLabels.pinRequiredError,
    pinValidationError: userProfileWebContentLabels.pinValidationError,
    sqLabel: userProfileWebContentLabels.sqLabel,
    sqlPlaceholder: userProfileWebContentLabels.sqlPlaceholder,
    sqRequiredError: userProfileWebContentLabels.sqRequiredError,
    sqaRequiredError: userProfileWebContentLabels.sqaRequiredError,
    addressLineOneInputLabel: userProfileWebContentLabels.addressLineOneInputLabel,
    addressLineTwoInputLabel: userProfileWebContentLabels.addressLineTwoInputLabel,
    zipInputLabel: userProfileWebContentLabels.zipInputLabel,
    cityLabel: userProfileWebContentLabels.cityLabel,
    stateLabel: userProfileWebContentLabels.stateLabel,
    phoneNumberInputLabel: userProfileWebContentLabels.phoneNumberInputLabel,
    preferredLanguageLabel: userProfileWebContentLabels.preferredLanguageLabel,
    phoneNumberValidationError: userProfileWebContentLabels.phoneNumberValidationError,
    requiredError: userProfileWebContentLabels.requiredError,
    sqaMinValueError: userProfileWebContentLabels.sqaMinValueError,
    preferredLanguageRequiredError: userProfileWebContentLabels.preferredLanguageRequiredError,

    secretQuestionsList: secretQuestionsListLabels.getAllAsArray(),
  };

  const declineTerms: DeclineTerms = {
    declineTermsHeader: labels.declineTermsHeader,
    declineTermsDescription: labels.declineTermsDescription,
    message: labels.declineTermsMessage,
    declineTermsLabel: labels.declineTermsLabel,
    continueEnrollLabel: labels.continueEnrollLabel,
  };

  const termsAndConditionsWebContent: TermsAndConditionsWebContent = {
    header: labels.termsAndConditionsHeader,
    description: labels.termsAndConditionsDesc,
    effectiveAsOf: labels.effectiveAsOf,

    acceptTermsConfirmationMsg: labels.acceptTermsConfirmationMsg,
    acceptTermsLabel: labels.acceptTerms,
    declineTermsExitEnrollLabel: labels.declineTerms,
    termsErrorMessage: labels.termsAndConditionsErrorMessage,

    // Decline terms modal
    declineTerms,
  };

  const infoPageEnrollmentLabels = informationEnrollmentPageLabels.getAll();
  const informationPageEnrollmentContent: InformationPageContent = {
    header: infoPageEnrollmentLabels.header?.primary,
    subHeader: infoPageEnrollmentLabels.header?.secondary,
    headerFooter: infoPageEnrollmentLabels.headerFooter?.primary,
    bodyFooterTitle: infoPageEnrollmentLabels.bodyFooter?.primary,
    bodyFooterSubtitle: infoPageEnrollmentLabels.bodyFooter?.secondary,
    continueButtonLabel: infoPageEnrollmentLabels.continueButtonLabel?.primary,
  };

  const infoPageSubscriptionLabels = informationSubscriptionPageLabels.getAll();
  const informationPageSubscriptionContent: InformationPageContent = {
    header: infoPageSubscriptionLabels.header?.primary,
    subHeader: infoPageSubscriptionLabels.header?.secondary,
    bodyHeader: infoPageSubscriptionLabels.bodyHeader?.primary,
    bodyList: infoPageSubscriptionLabels.bodyList?.primary,
    bodyFooter: infoPageSubscriptionLabels.bodyFooter?.primary,
    servicesTitle: infoPageSubscriptionLabels.services?.primary,
    continueButtonLabel: infoPageSubscriptionLabels.continueButtonLabel?.primary,
    availabilityNote: infoPageSubscriptionLabels.availabilityNote?.primary,
  };

  const wifiWebContent: WifiWebContent = {
    headerLabel: labels.attWifiHeaderLabel,
    descriptionLabel: labels.attWifiDescriptionLabel,
    activateLabel: labels.attWifiActivateLabel,
    headerSuccessLabel: labels.attWifiHeaderSuccessLabel,
    descriptionSuccessLabel: labels.attWifiDescriptionSuccessLabel,
    activateSuccessLabel: labels.attWifiActivateSuccessLabel,
  };

  const addressFormWebContent: AddressFormWebContent = {
    billingAddressLabel: labels.billingAddressLabel,
    billingAndMailingSameLabel: labels.billingAndMailingSameLabel,
    cityError: labels.cityError,
    cityLabel: labels.cityLabel,
    cityPlaceholder: labels.cityPlaceholder,
    countryError: labels.countryError,
    countryLabel: labels.countryLabel,
    countryPlaceholder: labels.countryPlaceholder,
    mailingAddressLabel: labels.mailingAddressLabel,
    phoneError: labels.phoneError,
    phoneRequiredError: labels.phoneRequiredError,
    postalCodeError: labels.postalCodeError,
    postalCodeLabel: labels.postalCodeLabel,
    postalCodePlaceholder: labels.postalCodePlaceholder,
    stateError: labels.stateError,
    stateLabel: labels.stateLabel,
    statePlaceholder: labels.statePlaceholder,
    streetError: labels.streetError,
    streetLabel: labels.streetLabel,
    streetPlaceholder: labels.streetPlaceholder,
    streetTwoLabel: labels.streetTwoLabel,
    streetTwoPlaceholder: labels.streetTwoPlaceholder,
    submitButtonLabel: labels.submitButtonLabel,
    title: labels.title,
  };

  const CFDILabels: CFDIWebContent = {
    usoCFDI: labels.usoCFDILabel,
    usoCFDIError: labels.usoCFDILabelError,
    usoCFDIPlaceholder: labels.usoCFDIPlaceholder,
    usoCFDIOptions: labels.usoCFDIOptions,

    regimenFiscalReceptor: labels.regimenFiscalReceptorLabel,
    regimenFiscalReceptorError: labels.regimenFiscalReceptorLabelError,
    regimenFiscalReceptorPlaceholder: labels.regimenFiscalReceptorPlaceholder,
    regimenFiscalReceptorOptions: labels.regimenFiscalReceptorOptions,

    postalCodeCFDISameAsHomeZipCode: labels.postalCodeCFDISameAsHomeZipCodeLabel,
    postalCodeCFDI: labels.postalCodeCFDILabel,
    postalCodeCFDIError: labels.postalCodeCFDILabelError,
    postalCodeCFDIPlaceholder: labels.postalCodeCFDIPlaceholder,
  };

  const authCodeFormContentLabels = authCodeFormListLabels.getAll();
  const authCodeFormContent: AuthCodeFormContent = {
    title: authCodeFormContentLabels.title,
    bodyHeadUnit: authCodeFormContentLabels.bodyHeadUnit,
    enterSecurityCode: authCodeFormContentLabels.enterSecurityCode,
    submitButton: authCodeFormContentLabels.submitButton,
    inputFieldsRRS: authCodeFormContentLabels.inputFieldsRRS,
    otpErrorMessage: authCodeFormContentLabels.otpErrorMessage,
    otpErrorTitle: authCodeFormContentLabels.otpErrorTitle,
    tryDifferentRecoveryMethod: authCodeFormContentLabels.tryDifferentRecoveryMethod,
    skipLabel: authCodeFormContentLabels.skipLabel,
    otpResendErrorMessage: authCodeFormContentLabels.otpResendErrorMessage,
    successfulHeaderText: authCodeFormContentLabels.successfulHeaderText,
    otpStatusPending: authCodeFormContentLabels.otpStatusPending,
    authorizeNow: authCodeFormContentLabels.authorizeNow,
  };

  return {
    // Trial
    trialPackagesWebContent,
    // Promo
    promoPackagesWebContent,
    // Packages
    packagesWebContent,
    // Addons
    addOnsWebContent,
    // Common
    commonWebContent,
    // User profile
    userProfileWebContent,
    // Nissan CPO
    nissanCpoWebContent,
    // Terms and Conditions
    termsAndConditionsWebContent,
    // Information page
    informationPageSubscriptionContent,
    informationPageEnrollmentContent,

    // Payment
    paymentInfoLabel: labels.paymentInfo,
    creditCardInfoLabel: labels.creditCardInfo,
    requiredFieldLabel: labels.required,
    cardNumberFieldLabel: labels.cardNumber,
    expirationFieldLabel: labels.expiration,
    cardholderNamedFieldLabel: labels.cardholderName,
    addressLine1FieldLabel: labels.addressLineOne,
    addressLine2FieldLabel: labels.addressLineTwo,
    zipCodeFieldLabel: labels.zipCode,
    enterNewCardLabel: labels.enterNewCard,
    useCardOnFileLabel: labels.useCardOnFile,
    applePayMethodLabel: labels.applePayMethodLabel,
    paypalPayMethodLabel: labels.paypalPayMethodLabel,
    paymentTermsAndConditions: `${labels.paymentTermsAndConditions}`,
    requiredFieldErrorMsg: labels.thisIsRequiredField,
    invalidInputFieldErrorMsg: labels.invalidInput,
    paymentErrorMsg: labels.paymentError,
    paymentBillChargeErrorMsg: labels.paymentBillChargeError,
    paymentServerErrorMsg: labels.paymentServerErrorMessage,
    paypalAgreementTermsErrorMsg: labels.paypalAgreementTermsErrorMsg,
    paymentTerms: labels.paymentTerms || '',
    additionalPaymentTerms,
    applePayDisplayName: labels.applePayDisplayName,
    taxLabel: labels.taxLabel || '',
    totalLabel: labels.totalLabel || '',
    taxDescription: labels.taxDescription || '',
    renewalInfo: labels.renewalInfo || '',
    creditCardInfo: labels.creditCardInfo,
    newPaymentInfo: labels.newPaymentInfo,
    addPaymentMethod: labels.addPaymentMethod,
    replacePaymentMethod: labels.replacePaymentMethod,
    showTaxSplit: labels.showTaxSplit || '',
    deletePaymentMethodConfirmation: labels.deletePaymentMethodConfirmation || '',
    fontStyle: labels.fontStyle,
    noPaymentsHeader: labels.noPaymentsHeader,
    noPaymentsSubscribeLink: labels.noPaymentsSubscribeLink,

    // All Card types
    cardTypes: cardTypes.getAllPrimaries(),

    // States
    mexicoStates: mexicoStateStatesListLabels.getAllPrimaries(),

    // Review Order
    reviewOrderLabel: labels.reviewOrder,
    paymentMethodLabel: labels.paymentMethod,
    cardNumberEndingLabel: labels.cardNumberEnding,
    billingAddressLabel: labels.billingAddress,
    orderSummaryLabel: labels.orderSummary,
    subscriptionSummaryLabel: labels.subscriptionSummary,
    subTotalLabel: labels.subTotal,
    packagePriceLabel: labels.packagePrice,
    refundLabel: labels.refund,
    refundTaxLabel: labels.refundTaxLabel,
    refundDueLabel: labels.refundDueLabel,
    discountLabel: labels.discount,
    totalTaxLabel: labels.totalTax,
    totalAmountLabel: labels.totalAmount,
    paymentDateLabel: labels.paymentDate,
    prorationLegal: labels.prorationLegal,
    taxLegalPayNow: labels.taxLegalPayNow,
    taxLegalPayLater: labels.taxLegalPayLater,
    subscribeNowButtonLabel: labels.subscribeNow,
    reviewOrderTerms: labels.reviewOrderTerms,

    // Confirm Order
    confirmOrderHeaderLabel: labels.orderSuccessful,
    confirmOrderDescriptionLabel: labels.successOrderMsg,
    confirmOrderEmailLabel: labels.successOrderEmail,
    confirmOrderUpdateEmergencyContacts: labels.updateEmergencyContacts,
    doneButtonLabel: labels.done,
    profileButtonLabel: labels.PROFILE,
    contactCustomerCareHeader: labels.contactCustomerCareHeader,
    contactCustomerCareBody: labels.contactCustomerCareBody,

    // Auth code form
    authCodeFormContent,

    // Privacy Policy
    privacyPolicyLinkLabel: labels.privacyPolicy,

    // Att Wifi
    wifiWebContent,

    // Address Form
    addressFormWebContent,

    // CFDI
    CFDILabels,

    // Designated refund message
    designatedRefundDealerMessageHeader: labels.designatedRefundDealerMessageHeader,
    designatedRefundDealerMessage: labels.designatedRefundDealerMessage,
  };
};

type useGenerateSubscriptionContentArg = {
  labels: ContentfulLabelType[];
  filterGroup?: BrandNamesFilterGroup;
  formFields: FormField[];
  assets: ServerAsset[];
};

export const useGenerateSubscriptionContent = ({
  labels,
  filterGroup,
  formFields,
  assets = [],
}: useGenerateSubscriptionContentArg) => {
  const generatedLabels = useGenerateSubscriptionLabels(labels, filterGroup);
  const assetsObject = generateAssetsObjects(assets);

  return {
    assets: assetsObject,
    carouselList: [],
    formFields,
    ...generatedLabels,
  };
};

export const useGenerateSubscriptionProps = () => {
  const preferences = usePreferencesSelector();

  const {
    mailingCountry,
    userName,
    homeAddress,
    mailingAddress: mailingAddressData,
    homeAddressSameAsMailingAddress,
    billingId,
    isPinConfigured,
    email,
    primaryPhone,
    secondaryPhone,
    preferredLanguage,
  } = useSelector(
    ({ accountReducer }: RootState) =>
      (accountReducer.account?.data || { userName: {}, homeAddress: {} }) as AccountData,
  );

  const api = useApi();

  const vehicleData = api.storeService.getVehicleData();

  // This code is temporary and should be delete after https://jira.atxg.com/browse/CPSPMNT-307 would be in place
  const telematicsProgramId = vehicleData?.telematicsProgramId?.toLowerCase();
  if (!vehicleData?.region && telematicsProgramId) {
    const telematicWords = telematicsProgramId.split(/[-_]/);
    const [brandName] = ['honda', 'acura'].filter((brand) => telematicWords.includes(brand));
    const [regionAbbreviation] = ['us', 'pr', 'hi', 'ca'].filter((regionAbbr) => telematicWords.includes(regionAbbr));

    if (brandName) {
      vehicleData.region = `${brandName}_${regionAbbreviation}`;
    }
  }

  const billingAddress = {
    address1: homeAddress?.street,
    address2: homeAddress?.unit,
    city: homeAddress?.city,
    state: homeAddress?.state,
    postalCode: homeAddress?.zip,
    country: mailingCountry || vehicleData?.registrationCountry,
    region: vehicleData?.region,
  };

  const mailingAddress =
    homeAddressSameAsMailingAddress && mailingAddressData.unit
      ? billingAddress
      : {
          address1: mailingAddressData?.street,
          address2: mailingAddressData?.unit,
          city: mailingAddressData?.city,
          state: mailingAddressData?.state,
          postalCode: mailingAddressData?.zip,
          country: mailingCountry || vehicleData?.registrationCountry,
        };

  const userDetails = {
    isPinConfigured,
    userId: api.storeService.getUserId(),
    firstName: userName?.givenName,
    lastName: userName?.fathersName,
    middleName: userName?.mothersName,
    email: email,
    phone: primaryPhone?.number,
    primaryPhone,
    secondaryPhone,
    billingId,
    billingAddress: omitRecursivelyBy(billingAddress, isInvalidValue),
    mailingAddress: omitRecursivelyBy(mailingAddress, isInvalidValue),
    homeAddressSameAsMailingAddress,
    preferredLanguage,
  };

  const vehicleDetails = {
    vehicleId: vehicleData?.vehicleId,
    make: vehicleData?.make,
    telematicsProgramId: vehicleData?.telematicsProgramId,
    vin: vehicleData?.vin,
    registrationCountry: vehicleData?.registrationCountry,
    ownershipType: vehicleData?.ownershipType,
    model: vehicleData?.model,
    year: vehicleData?.year,
    color: vehicleData?.color,
    trimLevel: vehicleData?.trimLevel,
  };

  const accessToken = api.storeService.getAccessToken() || undefined;
  const refreshToken = api.storeService.getRefreshToken() || undefined;

  const subscriptionProps: SubscriptionProps = {
    correlationId: '',
    ...(accessToken && { accessToken }),
    ...(refreshToken && { refreshToken }),
    locale: api.storeService.getContentfulLocale() || api.storeService.getLocale(),
    config,
    tenantId: api.storeService.getTenantId() || undefined,
    userDetails: omitRecursivelyBy(userDetails, isInvalidValue),
    vehicleDetails: omitRecursivelyBy(vehicleDetails, isInvalidValue),
    customerCareNumber: preferences.customerCareNumber,
    supportedCreditCards: preferences.supportedCreditCards?.split(','),
    applePaySupportedNetworks: preferences.applePaySupportedNetworks?.split(','),
    showDatesOnPromoPage: get('showDatesOnPromoPage'),
    isSinglePaymentMethod: get('isSinglePaymentMethod'),
    isAuthCodeFunctionality: get('isAuthCodeFunctionality'),
    hideDownloadAppOnConfirmation: get('hideDownloadAppOnConfirmation'),
    shouldMatchTrialWithCCRequired: get('shouldMatchTrialWithCCRequired'),
  };

  return subscriptionProps;
};
