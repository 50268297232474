import React, { useState, forwardRef } from 'react';
import { Button, buildOfferDetailKeys, replaceTemplateString } from '@app/components-lib';
import { Collapse, Divider, Stack } from '@mui/material';
import { useCancelLabels } from '@components/Cancellation/CancelLabelsContext';
import { useCancelMachineContext } from '@components/Cancellation/CancelSubscriptionStateMachineWrapper';
import PackageTrialItem from './PackageTrialItem';
import PromoPackage from './PackagePromotion';
import styles from '../../Cancellation.module.css';
import usePreferencesSelector from '@redux/selectors/preferences';
import useExpireRenewText from '@hooks/useExpireRenewText';
import { EligiblePackageInfo } from '@customTypes/PackageInfo';
import NavigationProp from '@customTypes/NavigationProp';

type Props = {
  onSelect: (pkg: EligiblePackageInfo) => void;
} & NavigationProp;

const Promotions = forwardRef(({ onSelect, Navigation }: Props, _ref) => {
  const [isListExpanded, setIsListExpanded] = useState(false);
  const preferences = usePreferencesSelector();

  const { selectedPackagesPromotions, otherPromotions } = useCancelMachineContext();
  const {
    currentPriceLabel,
    getThisDealLabel,
    hideCompleteList,
    offerDetailsLabel,
    packageDetailsLabel,
    promotionScreenHeader,
    promotionScreenSubHeader,
    regularPriceLabel,
    seeCompleteList,
    promotionalMessageHeader,
    promotionNotification,
    activateTrial,
    expires,
    offerDetailLabels,
    offerDetailsHeader,
    descriptions,
    defaultShortDescription,
    defaultLongDescription,
    assets,
    years,
    months,
    days,
    trial,
    renew,
    servicesHierarchy,
    currencyLabel,
  } = useCancelLabels();

  const trialToDisplay = otherPromotions.length ? otherPromotions[0] : null;
  const handleExpandList = () => {
    setIsListExpanded(!isListExpanded);
  };
  const expireRenewText = useExpireRenewText();

  const displayPromo = !!selectedPackagesPromotions.length;

  return (
    <Stack component="div" ref={_ref as React.RefObject<HTMLDivElement>} spacing={2}>
      <div className={styles['benefits-header-container']}>
        <h3 className={styles['all-names-of-packages']}>{promotionScreenHeader}</h3>
        <h4>{promotionScreenSubHeader}</h4>
        <Divider />
      </div>
      {displayPromo && (
        <Stack spacing={1}>
          {selectedPackagesPromotions.map((pkg, index) => {
            const matchingKey =
              buildOfferDetailKeys(pkg.variant, pkg.variant.discounts[0], pkg.packageName)?.find(
                (key) => offerDetailLabels[key],
              ) || '';

            const offerDetailsContent = replaceTemplateString(
              `${offerDetailsHeader} ${offerDetailLabels[matchingKey] || ''}`,
              {
                customerCareCancelPackageNumber: preferences.customerCareCancelPackageNumber,
              },
            );

            return (
              <Collapse in={index === 0 ? true : isListExpanded}>
                <PromoPackage
                  key={pkg.id}
                  offerPackage={pkg}
                  onSelect={() => onSelect(pkg)}
                  assets={assets}
                  labels={{
                    currentPrice: currentPriceLabel,
                    regularPrice: regularPriceLabel,
                    offerDetails: offerDetailsLabel,
                    packageDetails: packageDetailsLabel,
                    getDeal: getThisDealLabel,
                    promoHeader: replaceTemplateString(promotionalMessageHeader, { packageName: pkg.packageName }),
                    notification: promotionNotification,
                    offerDetailsContent,
                    descriptions,
                    defaultShortDescription,
                    defaultLongDescription,
                    servicesHierarchy,
                    currencyLabel,
                  }}
                />
              </Collapse>
            );
          })}
        </Stack>
      )}
      {displayPromo && selectedPackagesPromotions.length > 1 && (
        <>
          <Button className={styles['see-complete-list-button']} variant="text" onClick={handleExpandList}>
            {isListExpanded ? hideCompleteList : seeCompleteList}
          </Button>
          <Divider />
        </>
      )}
      {!displayPromo && trialToDisplay && (
        <>
          <PackageTrialItem
            trialPackage={trialToDisplay}
            labels={{
              activateTrial,
              years,
              months,
              days,
              trial,
              expiresDateText: expireRenewText({ ...trialToDisplay.variant, expiresLabel: expires, renewLabel: renew }),
            }}
            onSelect={onSelect}
            assets={assets}
          />
          <Divider />
        </>
      )}
      {Navigation && <Navigation />}
    </Stack>
  );
});

export default Promotions;
